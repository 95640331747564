import { colorsTuple, createTheme } from '@mantine/core'

export const baseSwayTheme = createTheme({
  fontFamily: 'Nunito Sans, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Nunito Sans, sans-serif' },
  cursorType: 'pointer',
  colors: {
    swurple: [
      '#fae9ff',
      '#edcfff',
      '#d79cff',
      '#c165fe',
      '#ae38fd',
      '#a31cfd',
      '#9400F6',
      '#8800e3',
      '#7900cb',
      '#6900b2',
    ],
    'blue-grey': [
      '#eef6fd',
      '#e4eaf1',
      '#c0ccda',
      '#9cb0c6',
      '#7688A9',
      '#6c89ab',
      '#6181a7',
      '#506f93',
      '#456285',
      '#355577',
    ],
    'deep-blue': [
      '#ebebff',
      '#d1d0fa',
      '#9e9df8',
      '#6865f7',
      '#3f39f6',
      '#291df7',
      '#2011f8',
      '#1707dd',
      '#0f04c5',
      '#000065',
    ],
    'peach-orange': colorsTuple('#EE9085'),
    'donation-green': colorsTuple('#00875A'),
  },
  primaryColor: 'swurple',
  other: {
    incomingMessageBgLight: '#E4EAF1',
    incomingMessageBgDark: '#7688A9',
    outgoingMessageBgLight: '#9400F6',
    outgoingMessageBgDark: '#9400F6',
  },
})
