import { Browser } from '@capacitor/browser'
import { Anchor, Text, TextProps } from '@mantine/core'
import LinkifyIt from 'linkify-it'
import React from 'react'
import { Link, useNavigate } from 'react-router'
import tlds from 'tlds'

import { isShortLink, resolveAndNavigate } from '@util/linkUtils'

interface LinkedTextProps {
  children: string
  internalDomains?: string[]
}

const DEFAULT_INTERNAL_DOMAINS = [
  'app-int.sway.dm',
  'swaydm.app',
  'sway.dm',
  'app.sway.dm',
]

/**
 * linkify-it instance enhanced with TLDs for better URL detection.
 * 🌐 See: https://data.iana.org/TLD/tlds-alpha-by-domain.txt
 */
const linkify = new LinkifyIt()
linkify.tlds(tlds)

/**
 * Checks if a URL is internal by comparing it to the current window host
 * or any user-supplied list of internal domains.
 */
function isInternalUrl(url: string, allowedDomains: string[]): boolean {
  try {
    const { host } = new URL(url)
    const currentHost =
      typeof window !== 'undefined' ? window.location.host : ''
    return host === currentHost || allowedDomains.includes(host)
  } catch {
    return false
  }
}

function stripDomain(url: string): string {
  try {
    const { pathname, search, hash } = new URL(url)
    return `${pathname}${search}${hash}`
  } catch {
    return url
  }
}

export function LinkedText({
  children,
  internalDomains = DEFAULT_INTERNAL_DOMAINS,
  ...rest
}: LinkedTextProps & TextProps) {
  const navigate = useNavigate()

  /**
   * Convert text into an array of text segments and link elements.
   * We rely on linkify-it to detect URLs.
   */
  function parseContent(text: string) {
    const matches = linkify.match(text)
    if (!matches) return [text]

    const nodes: React.ReactNode[] = []
    let lastIndex = 0

    matches.forEach((match, idx) => {
      if (match.index > lastIndex) {
        nodes.push(text.slice(lastIndex, match.index))
      }

      const url = match.url
      const textUrl = match.text

      if (isShortLink(url)) {
        // Short link => Let resolveAndNavigate handle the routing once resolved
        nodes.push(
          <Anchor
            key={`${url}-${idx}`}
            component="button"
            onClick={(e) => {
              e.preventDefault()
              resolveAndNavigate(url, navigate)
            }}
            c={rest.c}
          >
            {textUrl}
          </Anchor>
        )
      } else {
        // Non-short link => standard internal vs external logic
        if (isInternalUrl(url, internalDomains)) {
          const path = stripDomain(url)
          nodes.push(
            <Anchor
              key={`${url}-${idx}`}
              component={Link}
              to={path || '/'}
              c={rest.c}
            >
              {textUrl}
            </Anchor>
          )
        } else {
          nodes.push(
            <Anchor
              key={`${url}-${idx}`}
              component="button"
              onClick={async (e) => {
                e.preventDefault()
                await Browser.open({ url })
              }}
              c={rest.c}
            >
              {textUrl}
            </Anchor>
          )
        }
      }

      lastIndex = match.lastIndex
    })

    if (lastIndex < text.length) {
      nodes.push(text.slice(lastIndex))
    }
    return nodes
  }

  return (
    <Text
      styles={() => ({
        root: {
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        },
      })}
      {...rest}
    >
      {parseContent(children)}
    </Text>
  )
}
