import useAppContext from '@context/appContext/useAppContext'
import { useFeatureFlags } from '@context/index'
import {
  faArrowRightFromBracket,
  faCircleQuestion,
  faGear,
  faGraduationCap,
  faHome,
  faMobileScreen,
  faQrcode,
  faStore,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxDollar } from '@fortawesome/sharp-solid-svg-icons'
import { ProfileType, useWalletBalanceQuery } from '@graphql'
import useAnalytics from '@hooks/useAnalytics'
import { useAuth } from '@hooks/useAuth'
import { SwayIcon } from '@icons/Sway'
import { SwayCashIcon } from '@icons/SwayCash'
import { AppShell, Indicator, NavLink, Paper, Stack, Text } from '@mantine/core'
import { useLeaderboardsStore } from '@stores/useLeaderboardsStore'
import { SEMI_BOLD } from '@util/utils'
import { useCallback } from 'react'
import { Link, NavLink as RouterLink } from 'react-router'
import { useIntercom } from 'react-use-intercom'
import useLayoutContext from './useLayoutContext'
import { WalletBalanceDisplay } from './WalletBalanceDisplay'

type SwayNavbarLink = {
  to?: string
  label: string
  leftSection: React.ReactNode
  rightSection?: React.ReactNode
  indicator?: number | string
  onClick?: () => void
}

export function SwayNavbar({ closeSidebar }: { closeSidebar: () => void }) {
  const { boot, show } = useIntercom()
  const { currentUser, onLogout } = useAuth()

  const profileType = currentUser?.profileType

  const { data: walletBalanceData } = useWalletBalanceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const walletBalance = walletBalanceData?.wallet?.balance.amount

  const handleOnLogout = useCallback(() => {
    if (currentUser?.id) onLogout(currentUser?.id)
  }, [onLogout, currentUser?.id])

  const handleIntercomSupport = useCallback(() => {
    boot()
    show()
  }, [boot, show])

  return (
    <>
      {profileType === ProfileType.CommunityFollower && (
        <CommunityFollowerNavbar
          walletBalance={walletBalance}
          closeSidebar={closeSidebar}
          onLogout={handleOnLogout}
          onIntercomSupport={handleIntercomSupport}
        />
      )}
      {profileType === ProfileType.Community && (
        <VendorNavbar
          walletBalance={walletBalance}
          closeSidebar={closeSidebar}
          onLogout={handleOnLogout}
          onIntercomSupport={handleIntercomSupport}
        />
      )}
      {profileType !== ProfileType.CommunityFollower &&
        profileType !== ProfileType.Community && (
          <FallbackNavbar onLogout={handleOnLogout} />
        )}
    </>
  )
}

function FallbackNavbar({ onLogout }: { onLogout: () => void }) {
  return (
    <>
      <AppShell.Section>
        <Stack gap={0}>
          <SwayNavLink
            key="logout"
            label="logout"
            leftSection={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
            onClick={onLogout}
          />
        </Stack>
      </AppShell.Section>
    </>
  )
}

function CommunityFollowerNavbar({
  walletBalance,
  closeSidebar,
  onLogout,
  onIntercomSupport,
}: {
  walletBalance: number | undefined | null
  closeSidebar: () => void
  onLogout: () => void
  onIntercomSupport: () => void
}) {
  const { track } = useAnalytics()
  const { deviceInfo } = useAppContext()
  const { openGetAppModal } = useLayoutContext()
  const { contentFeed } = useFeatureFlags()

  const leaderboardsPageVisited = useLeaderboardsStore(
    (state) => state.leaderboardsPageVisited
  )

  const primaryLinks: Array<SwayNavbarLink> = [
    {
      to: '/redemptions',
      label: 'Redemptions',
      leftSection: <FontAwesomeIcon icon={faStore} className="h-5 w-5" />,
    },
    {
      to: '/challenges',
      label: 'Leaderboards',
      leftSection: (
        <Indicator processing disabled={leaderboardsPageVisited}>
          <FontAwesomeIcon icon={faTrophy} className="h-5 w-5" />
        </Indicator>
      ),
    },
    {
      to: '/referrals',
      label: 'Referrals',
      leftSection: <FontAwesomeIcon icon={faBoxDollar} className="h-5 w-5" />,
    },
  ]

  if (contentFeed) {
    primaryLinks.unshift({
      to: '/feed',
      label: 'Home',
      leftSection: <FontAwesomeIcon icon={faHome} className="h-5 w-5" />,
    })
  }

  const secondaryLinks: Array<SwayNavbarLink> = [
    {
      to: '/settings',
      label: 'Account Settings',
      leftSection: <FontAwesomeIcon icon={faGear} className="h-5 w-5" />,
    },
    deviceInfo?.platform === 'web'
      ? {
          label: 'Get the app',
          leftSection: (
            <FontAwesomeIcon icon={faMobileScreen} className="h-5 w-5" />
          ),
          onClick: () => {
            openGetAppModal()
            track('get-app-clicked', {
              additional_properties: { source: 'navbar' },
            })
          },
        }
      : undefined,
    {
      label: 'Contact Support',
      leftSection: (
        <FontAwesomeIcon icon={faCircleQuestion} className="h-5 w-5" />
      ),
      onClick: onIntercomSupport,
    },
    {
      to: 'https://www.sway.dm/info',
      label: 'About SwayDM',
      leftSection: <SwayIcon className="h-5 w-5" />,
    },
    {
      label: 'Log out',
      leftSection: (
        <FontAwesomeIcon icon={faArrowRightFromBracket} className="h-5 w-5" />
      ),
      onClick: onLogout,
    },
  ].filter((link) => link !== undefined) as Array<SwayNavbarLink>

  return (
    <>
      <AppShell.Section>
        <Stack gap={0}>
          {primaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              rightSection={link.rightSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
        </Stack>
      </AppShell.Section>
      <AppShell.Section grow>
        {walletBalance !== undefined && walletBalance !== null && (
          <Paper
            component={Link}
            to="/earn"
            radius="md"
            shadow="md"
            p="md"
            withBorder
            maw={300}
          >
            <Text size="lg" fw={SEMI_BOLD}>
              SwayCash Balance
            </Text>
            <Text size="md" fw={SEMI_BOLD}>
              <WalletBalanceDisplay size="md" walletBalance={walletBalance} />
            </Text>
          </Paper>
        )}
      </AppShell.Section>
      <AppShell.Section>
        <Stack gap={0}>
          {secondaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
        </Stack>
      </AppShell.Section>
    </>
  )
}

function VendorNavbar({
  walletBalance,
  closeSidebar,
  onLogout,
  onIntercomSupport,
}: {
  walletBalance: number | undefined | null
  closeSidebar: () => void
  onLogout: () => void
  onIntercomSupport: () => void
}) {
  const { track } = useAnalytics()
  const { deviceInfo } = useAppContext()
  const { openGetAppModal } = useLayoutContext()
  const { vendorDashboard } = useFeatureFlags()

  const includeDashboard = vendorDashboard
    ? [
        {
          to: '/vendor/dashboard',
          label: 'Dashboard',
          leftSection: <FontAwesomeIcon icon={faHome} className="h-5 w-5" />,
        },
      ]
    : []

  const primaryLinks: Array<SwayNavbarLink> = [
    ...includeDashboard,
    {
      to: '/vendor/redemptions',
      label: 'Redemptions',
      leftSection: <FontAwesomeIcon icon={faStore} className="h-5 w-5" />,
    },
    {
      to: '/vendor/qrcodes',
      label: 'QR Codes',
      leftSection: <FontAwesomeIcon icon={faQrcode} className="h-5 w-5" />,
    },
    {
      to: '/vendor/content/trivia-polls',
      label: 'Trivia & Polls',
      leftSection: (
        <FontAwesomeIcon icon={faGraduationCap} className="h-5 w-5" />
      ),
    },
  ]

  const secondaryLinks: Array<SwayNavbarLink> = [
    {
      to: '/settings',
      label: 'Account Settings',
      leftSection: <FontAwesomeIcon icon={faGear} className="h-5 w-5" />,
    },
    deviceInfo?.platform === 'web'
      ? {
          label: 'Get the app',
          leftSection: (
            <FontAwesomeIcon icon={faMobileScreen} className="h-5 w-5" />
          ),
          onClick: () => {
            openGetAppModal()
            track('get-app-clicked', {
              additional_properties: { source: 'navbar' },
            })
          },
        }
      : undefined,
    {
      label: 'Contact Support',
      leftSection: (
        <FontAwesomeIcon icon={faCircleQuestion} className="h-5 w-5" />
      ),
      onClick: () => {
        onIntercomSupport()
        track('contact-support-clicked', {
          additional_properties: { source: 'navbar' },
        })
      },
    },
    {
      to: 'https://www.sway.dm/info',
      label: 'About SwayDM',
      leftSection: <SwayIcon className="h-5 w-5" />,
      onClick: () => {
        track('about-sway-clicked', {
          additional_properties: { source: 'navbar' },
        })
      },
    },
    {
      label: 'Log out',
      leftSection: (
        <FontAwesomeIcon icon={faArrowRightFromBracket} className="h-5 w-5" />
      ),
      onClick: onLogout,
    },
  ].filter((link) => link !== undefined) as Array<SwayNavbarLink>

  return (
    <>
      <AppShell.Section>
        <Stack gap={0}>
          {primaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              rightSection={link.rightSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
          {deviceInfo?.platform === 'web' && (
            <SwayNavLink
              label="Mobile App"
              leftSection={
                <FontAwesomeIcon icon={faMobileScreen} className="w-4" />
              }
              onClick={() => {
                closeSidebar()
                openGetAppModal()
              }}
            />
          )}
        </Stack>
      </AppShell.Section>
      <AppShell.Section grow>
        {walletBalance !== undefined && walletBalance !== null && (
          <Paper
            component={Link}
            to="/earn"
            radius="md"
            shadow="md"
            p="md"
            withBorder
            onClick={closeSidebar}
          >
            <Text size="lg" fw={SEMI_BOLD}>
              SwayCash Balance
            </Text>
            <Text size="md" fw={SEMI_BOLD}>
              <SwayCashIcon className="mr-2 inline-block h-5 w-5" />
              {walletBalance && (walletBalance / 100).toFixed(2)} SC
            </Text>
          </Paper>
        )}
      </AppShell.Section>
      <AppShell.Section>
        <Stack gap={0}>
          {secondaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
        </Stack>
      </AppShell.Section>
    </>
  )
}

function SwayNavLink({
  to,
  label,
  leftSection,
  rightSection,
  indicator,
  onClick,
}: SwayNavbarLink) {
  const leftSectionWrapped = indicator ? (
    <Indicator label={indicator} size={16}>
      {leftSection}
    </Indicator>
  ) : (
    leftSection
  )

  const NavLinkContent = to ? (
    <NavLink
      component={RouterLink}
      to={to}
      label={label}
      leftSection={leftSectionWrapped}
      rightSection={rightSection}
      onClick={() => onClick?.()}
    />
  ) : (
    <NavLink
      component="button"
      label={label}
      leftSection={leftSectionWrapped}
      rightSection={rightSection}
      onClick={() => onClick?.()}
    />
  )

  return NavLinkContent
}
