import { faHeart as faHeartLight } from '@fortawesome/free-regular-svg-icons'
import {
  faHeart as faHeartSolid,
  faShare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { ActionIcon, Avatar, Box, Stack } from '@mantine/core'
import { Link } from 'react-router'

export function Overlay({
  avatarImageUrl,
  saved,
  vendorDisplayName,
  handleSave,
  handleUnsave,
  handleShare,
  redirectOnAvatarClick = true,
}: {
  avatarImageUrl?: string
  saved?: boolean
  vendorDisplayName?: string
  handleUnsave?: () => void
  handleSave?: () => void
  handleShare?: () => void
  redirectOnAvatarClick?: boolean
}) {
  const isMobileViewport = useIsMobileViewport()
  return (
    <Box
      pos="absolute"
      h="100%"
      w="100%"
      style={{ zIndex: 10, pointerEvents: 'none' }}
    >
      <Stack
        pos="absolute"
        align="center"
        justify="center"
        bottom={12}
        right={16}
        style={{ pointerEvents: 'auto' }}
      >
        {handleSave && handleUnsave && (
          <ActionIcon
            size={isMobileViewport ? '30' : '50'}
            variant="transparent"
            color={saved ? 'red' : 'white'}
            onClick={saved ? handleUnsave : handleSave}
          >
            <FontAwesomeIcon
              icon={saved ? faHeartSolid : faHeartLight}
              size={isMobileViewport ? 'lg' : 'xl'}
            />
          </ActionIcon>
        )}
        {handleShare && (
          <ActionIcon
            size={isMobileViewport ? '30' : '50'}
            variant="transparent"
            color="white"
            onClick={handleShare}
          >
            <FontAwesomeIcon
              icon={faShare}
              size={isMobileViewport ? 'lg' : 'xl'}
            />
          </ActionIcon>
        )}
        {avatarImageUrl &&
          vendorDisplayName &&
          (redirectOnAvatarClick ? (
            <Avatar
              component={Link}
              to={`/profile/${vendorDisplayName}`}
              src={avatarImageUrl}
            />
          ) : (
            <Avatar src={avatarImageUrl} />
          ))}
        {avatarImageUrl && !vendorDisplayName && (
          <Avatar src={avatarImageUrl} />
        )}
      </Stack>
    </Box>
  )
}
