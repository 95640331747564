import { Capacitor } from '@capacitor/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faHand } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetLocation } from '@hooks/useGetLocation'
import { Button, Stack, Text } from '@mantine/core'
import { BOLD, openAppSettings } from '@util/utils'

export const WaitLocationPermissionModalContent = ({
  onClose,
}: {
  onClose: () => void
}) => {
  const isNativePlatform = Capacitor.isNativePlatform()

  const { permissions, requestLocationPermission } = useGetLocation()

  return (
    <Stack
      align="center"
      h="100%"
      justify="center"
      gap={18}
      px={12}
      pos={'relative'}
    >
      <FontAwesomeIcon icon={faHand as IconProp} color={'white'} size="4x" />
      <Text fw={BOLD} c="white" className="text-3xl" ta="center">
        Wait!
      </Text>
      <Text fw={BOLD} c="white" ta="center" className="text-xl">
        SwayDM is meant to be local
      </Text>
      <Text c="white" ta="center" className="text-lg opacity-70">
        We do not store or track your location. Your data is yours! We'd like to
        make your SwayDM experience even better.
      </Text>
      <Stack w="100%" gap={18} pos="absolute" bottom={10}>
        <Button
          fullWidth
          color="deep-blue.9"
          onClick={() => {
            if (!permissions) {
              onClose()
              return
            }

            if (permissions.location === 'denied' && isNativePlatform) {
              openAppSettings()
            } else {
              requestLocationPermission()
            }
            onClose()
          }}
        >
          {permissions?.location === 'denied'
            ? 'Open Location Settings'
            : 'Share My Location'}
        </Button>
        <Button fullWidth color="white" variant="outline" onClick={onClose}>
          Maybe Later
        </Button>
      </Stack>
    </Stack>
  )
}
