import AppStoreBadge from '@assets/app-store-badge.png'
import GooglePlayBadge from '@assets/google-play-badge.png'
import useAnalytics from '@hooks/useAnalytics'
import { Anchor, Flex, Image } from '@mantine/core'

export const AppStoreLinks = () => {
  const { track } = useAnalytics()
  return (
    <Flex align="center" gap="md">
      <Anchor
        href="https://link.sway.dm/e/app-store/android"
        target="_blank"
        inherit
        onClick={() =>
          track('get-app-clicked', {
            additional_properties: { platform: 'android' },
          })
        }
      >
        <Image src={GooglePlayBadge} w={175} alt="Get it on Google Play" />
      </Anchor>
      <Anchor
        href="https://link.sway.dm/e/app-store/ios"
        target="_blank"
        inherit
        onClick={() =>
          track('get-app-clicked', {
            additional_properties: { platform: 'ios' },
          })
        }
      >
        <Image src={AppStoreBadge} w={175} alt="Get it on Apple App Store" />
      </Anchor>
    </Flex>
  )
}
