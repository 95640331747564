import { Browser } from '@capacitor/browser'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAnalytics from '@hooks/useAnalytics'
import { Avatar, Badge, Group, Stack, Text, Title } from '@mantine/core'
import { BOLD } from '@util/utils'
import { Link, useLocation } from 'react-router'
import { UnclaimedChip } from './UnclaimedChip'

export type HeroSectionProps = {
  avatarImageUrl: string
  displayName: string
  name: string
  unclaimed: boolean
  verified: boolean
  isNonProfit: boolean
  website?: {
    url: string
    display: string
  }
}

export function HeroSection({
  avatarImageUrl,
  displayName,
  name,
  unclaimed,
  verified,
  website,
  isNonProfit,
}: HeroSectionProps) {
  const handleWebsiteClick = (url: string) => {
    Browser.open({
      url,
    })
  }

  const location = useLocation()
  const { track } = useAnalytics()
  const redirectToProfile = !location.pathname.includes('/profile')
  const showingBadges = unclaimed || verified
  const titleOrder = Math.ceil(name.length / 5) as 1 | 2 | 3 | 4

  return (
    <Stack align="center" gap={4}>
      {redirectToProfile ? (
        <Avatar
          src={avatarImageUrl}
          size={60}
          component={Link}
          to={`/profile/${displayName}`}
          onClick={() =>
            track('profile-avatar-clicked', {
              additional_properties: { redirect_to_profile: true },
            })
          }
        />
      ) : (
        <Avatar
          src={avatarImageUrl}
          size={60}
          onClick={() =>
            track('profile-avatar-clicked', {
              additional_properties: { redirect_to_profile: false },
            })
          }
        />
      )}
      {redirectToProfile ? (
        <Link to={`/profile/${displayName}`}>
          <Title order={titleOrder} maw={250} fw={BOLD} ta="center">
            {name}
          </Title>
        </Link>
      ) : (
        <Title order={titleOrder} maw={250} fw={BOLD} ta="center">
          {name}
        </Title>
      )}
      {displayName &&
        (redirectToProfile ? (
          <Text
            fw={400}
            ta="center"
            truncate
            maw={250}
            component={Link}
            to={`/profile/${displayName}`}
          >
            @{displayName}
          </Text>
        ) : (
          <Text fw={400} ta="center" truncate maw={250}>
            @{displayName}
          </Text>
        ))}
      {isNonProfit && (
        <Badge
          size="lg"
          color="peach-orange"
          onClick={() => {
            track('profile-non-profit-clicked')
          }}
        >
          Non-Profit Organization
        </Badge>
      )}
      {website && (
        <Text
          fw={400}
          ta="center"
          className="cursor-pointer"
          onClick={() => handleWebsiteClick(website.url)}
        >
          {website.display}
        </Text>
      )}
      {showingBadges && (
        <Group gap={4} mt={4}>
          {unclaimed && <UnclaimedChip vendorDisplayName={name} />}
          {verified && (
            <Badge
              color="swurple"
              leftSection={<FontAwesomeIcon icon={faCircleCheck} />}
            >
              Verified
            </Badge>
          )}
        </Group>
      )}
    </Stack>
  )
}
