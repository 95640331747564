import { SwayFeedbackButton } from '@components/swayFeedbackButton/SwayFeedbackButton'
import { Button, Card, Center, Group, Stack, Text } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { SEMI_BOLD } from '@util/utils'

export const SwayError: Sentry.FallbackRender = ({
  error,
  componentStack,
  eventId,
}) => {
  console.error('Error:', error instanceof Error ? error.toString() : '')
  console.error('Component Stack:', componentStack)
  console.error('Event ID:', eventId)

  return (
    <Center h="100vh" px={16}>
      <Card
        radius="md"
        padding="lg"
        withBorder
        style={{
          borderColor: 'red',
          borderWidth: '2px',
        }}
      >
        <Stack>
          <Text size="xl" fw={SEMI_BOLD} ta="center" style={{ color: 'red' }}>
            Oops! Something went wrong.
          </Text>
          <Text size="sm" ta="center">
            We encountered an error. Please refresh the page or try again later.
          </Text>
          <Group justify="center">
            <Button onClick={() => window.location.reload()}>
              Refresh page
            </Button>
            <SwayFeedbackButton />
          </Group>
        </Stack>
      </Card>
    </Center>
  )
}
