import {
  FilterFormValues,
  useRedemptionFiltersContext,
} from '@context/redemptionFiltersContext'
import {
  FILTERS,
  MAX_FILTER_PRICE,
  SWAYCASH_WITHOUT_DECIMALS_FACTOR,
} from '@context/redemptionFiltersContext/redemptionFilters.constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/sharp-regular-svg-icons'
import {
  MediaOption,
  PageOrderDirection,
  RedemptionQueryAttributes,
  RedemptionsDiscoveryQueryVariables,
} from '@graphql'
import useAnalytics from '@hooks/useAnalytics'
import { SwayCashIcon } from '@icons/SwayCash'
import { Button, Group, Pill, Text } from '@mantine/core'
import { milesToKm } from '@util/utils'
import { useCallback } from 'react'
import { useLocation } from 'react-router'
type FilterFormValuesForBadges = Omit<
  FilterFormValues,
  'sortBy' | 'useCurrentLocation'
>

const generatePillWrapper = (children: JSX.Element) => {
  return (
    <Group wrap="nowrap" gap={4} justify="center" align="center" h={'100%'}>
      {children}
    </Group>
  )
}

const generatePricePillText = (max: number, min: number) => {
  const hasMaxSet = max === MAX_FILTER_PRICE
  // We don't show decimals in the filter by price pill.
  const minWithoutDecimals = min / SWAYCASH_WITHOUT_DECIMALS_FACTOR
  const maxWithoutDecimals = max / SWAYCASH_WITHOUT_DECIMALS_FACTOR

  if (hasMaxSet)
    return (
      <>
        <SwayCashIcon className="h-3 w-3" />
        <Text size="sm">{minWithoutDecimals}+</Text>
      </>
    )

  return (
    <>
      <SwayCashIcon className="h-3 w-3" />
      <Text size="sm">{minWithoutDecimals} to </Text>
      <SwayCashIcon className="h-3 w-3" />
      <Text size="sm">{maxWithoutDecimals} </Text>
    </>
  )
}

export const RedemptionsFiltersBadges = ({
  currentFilters,
  setFilters,
  refetchRedemptions,
  removeFilter,
  imgixOpts,
}: {
  currentFilters: FilterFormValues
  setFilters: (values: FilterFormValues) => void
  refetchRedemptions: (
    queryArguments: RedemptionsDiscoveryQueryVariables
  ) => void
  removeFilter: (newFilters: FilterFormValues) => void
  imgixOpts: MediaOption[]
}) => {
  const { track } = useAnalytics()
  const {
    filtersForm,
    currentSelectedVendorsForFilter,
    defaultFilters,
    generateFlopEntry,
  } = useRedemptionFiltersContext()
  const location = useLocation()
  const isInSavedSection = location.pathname === '/redemptions/saved'

  const generateFilterLabel = useCallback(
    (key: string, value: any): JSX.Element => {
      switch (key) {
        case FILTERS.PRICE_BETWEEN: {
          const [min, max] = value as [number, number]

          const pillContent = generatePricePillText(max, min)

          return generatePillWrapper(pillContent)
        }
        case FILTERS.VENDORS_ID: {
          const vendorsId = value as string[]
          const vendorsIdLength: number = vendorsId.length
          const pillContent = (
            <>
              <Text size="sm">Vendor</Text>
              <Text size="sm">
                {vendorsIdLength === 1
                  ? `is ${
                      currentSelectedVendorsForFilter.find(
                        (v) => v.value == vendorsId[0]
                      )?.label
                    }`
                  : `is one of ${vendorsIdLength} vendors`}
              </Text>
            </>
          )

          return generatePillWrapper(pillContent)
        }
        case FILTERS.RADIUS_KM: {
          const radius = value as number
          const pillContent = (
            <Group gap={8} wrap="nowrap">
              <FontAwesomeIcon icon={faLocationDot} />
              <Text size="sm">{`< ${radius / 10} mi`}</Text>
            </Group>
          )

          return generatePillWrapper(pillContent)
        }

        case FILTERS.ONLY_DONATIONS: {
          const onlyNonProfit = value as boolean

          if (!onlyNonProfit) return <></>

          const pillContent = <Text size="sm">{'Donations Only'}</Text>

          return generatePillWrapper(pillContent)
        }

        case FILTERS.EXCLUDE_EXPIRED: {
          const pillContent = <Text size="sm">Excluding Expired</Text>

          return generatePillWrapper(pillContent)
        }

        default:
          return <></>
      }
    },
    [currentSelectedVendorsForFilter]
  )

  const handleClearFilters = useCallback(() => {
    track('redemptions-filters-cleared', {
      additional_properties: { source: 'discovery' },
    })
    const queryObject = {
      query: {
        limit: 100,
        filters: generateFlopEntry(FILTERS.RADIUS_KM, milesToKm(100))
          .filtersInput,
        orderBy: [
          {
            attribute: RedemptionQueryAttributes.RadiusKm,
            direction: PageOrderDirection.Asc,
          },
        ],
      },
      imgixOpts: imgixOpts,
    }

    filtersForm.reset()
    setFilters(defaultFilters)
    refetchRedemptions(queryObject)
  }, [
    defaultFilters,
    filtersForm,
    generateFlopEntry,
    imgixOpts,
    refetchRedemptions,
    setFilters,
    track,
  ])

  const handleRemoveFilter = useCallback(
    (key: keyof FilterFormValues) => {
      const newFilters = {
        ...currentFilters,
        [key]: defaultFilters[key],
      }

      filtersForm.setValues(newFilters)
      filtersForm.resetDirty({ [key]: defaultFilters[key] })
      track('redemptions-filters-removed', {
        additional_properties: { source: 'discovery', filter: key },
      })
      removeFilter(newFilters)
    },
    [currentFilters, defaultFilters, filtersForm, removeFilter, track]
  )

  // For the save redemptions page, we remove the radius_km badges
  const parsedFilters = {
    ...currentFilters,
    ...(isInSavedSection ? { radiusKm: undefined } : {}),
  }

  const parsedDefaultFilters = {
    ...defaultFilters,
    ...(isInSavedSection ? { radiusKm: undefined } : {}),
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  // Removing sortBy and useCurrentLocation from the filters because we don't want to show them as badges.
  const {
    sortBy: sortByCurrent,
    useCurrentLocation: useCurrentLocationCurrent,
    ...currentFiltersForBadges
  } = parsedFilters

  // Also removing sortBy and useCurrentLocation from the default filters for comparison.
  const {
    sortBy: sortByDefault,
    useCurrentLocation: useCurrentLocationDefault,
    ...defaultFiltersForBadges
  } = parsedDefaultFilters
  /* eslint-enable @typescript-eslint/no-unused-vars */

  if (
    Object.keys(currentFiltersForBadges).length === 0 ||
    JSON.stringify(currentFiltersForBadges) ===
      JSON.stringify(defaultFiltersForBadges)
  )
    return <></>

  return (
    <Group px={4} my={8} gap={6}>
      {Object.keys(currentFiltersForBadges).map((key, index) => {
        const formKey = key as keyof FilterFormValuesForBadges
        const value = currentFiltersForBadges[formKey]

        if (
          !value ||
          value.toString() === defaultFilters[formKey]?.toString()
        ) {
          return
        } else {
          return (
            <Pill
              withRemoveButton
              key={index}
              size="lg"
              py={2}
              style={{
                background: 'transparent',
                border: '1px solid navy',
                color: 'navy',
              }}
              onRemove={() => handleRemoveFilter(formKey)}
            >
              {generateFilterLabel(key, value)}
            </Pill>
          )
        }
      })}

      <Button
        fullWidth
        variant="outline"
        color="navy"
        styles={{ root: { borderWidth: 1 } }}
        onClick={() => handleClearFilters()}
      >
        Clear Filters
      </Button>
    </Group>
  )
}
