import { print } from '@apollo/client/utilities'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  TosAcceptDocument,
  useTosAcceptMutation,
  useTosCurrentQuery,
  useUserQuery,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import {
  Blockquote,
  Button,
  Center,
  Divider,
  Flex,
  Modal,
  Stack,
  Text,
  Title,
  TypographyStylesProvider,
} from '@mantine/core'
import { useDebouncedValue, useDisclosure } from '@mantine/hooks'
import * as Sentry from '@sentry/react'
import { notifications } from '@util/notifications/notifications'
import { useEffect, useMemo } from 'react'

const ROOT_API_SERVER_URL = import.meta.env.VITE_ROOT_API_SERVER_URL

export function RequireToS() {
  const [opened, { open, close }] = useDisclosure()
  const [debouncedOpened] = useDebouncedValue(opened, 1000, { leading: false })
  const { onLogout, allUsers, currentUser } = useAuth()

  const {
    data: userData,
    loading: userLoading,
    refetch: refetchUsers,
  } = useUserQuery({
    fetchPolicy: 'cache-only',
  })
  const { data: tosCurrentData, loading: tosCurrentLoading } =
    useTosCurrentQuery()

  const [acceptTos, { loading: pendingTOSAccept }] = useTosAcceptMutation()

  const userAcceptedTOSVersion =
    userData?.user?.acceptedTerms &&
    userData?.user?.lastTosAccepted?.version.version
  const currentTOSVersion = tosCurrentData?.tosCurrent?.version

  const content = tosCurrentData?.tosCurrent?.content

  const closeTOSModal = () => {
    close()
  }

  async function accept() {
    const version = tosCurrentData?.tosCurrent.version

    if (!version) {
      notifications.show({
        title: 'Error',
        message: 'Failed to retrieve TOS version',
        color: 'red',
      })
      return
    }

    // * gql Documents are created using gql which is an Abstract Syntax Tree (AST) representation of a GraphQL query
    // * We have to "print" it using the Apollo Client's print function to convert it to a string
    const tosAcceptMutation = print(TosAcceptDocument)

    // Send mutation for all users except the current one
    for (const user of allUsers) {
      if (user.id === currentUser?.id) {
        continue
      }

      try {
        await fetch(`${ROOT_API_SERVER_URL}/graphql`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken?.value}`,
          },
          credentials: 'include',
          body: JSON.stringify({
            query: tosAcceptMutation,
            variables: { version },
          }),
        })
      } catch (error) {
        console.error(`Error accepting ToS for user ${user.id}:`, error)

        Sentry.captureException(error, {
          extra: {
            query: 'TOS_ACCEPT',
            userId: user.id,
          },
        })
      }
    }

    // Accept ToS for the current user
    acceptTos({
      variables: { version },
      onCompleted: () => {
        notifications.show({
          title: 'Success',
          message: 'Terms and Conditions accepted',
          color: 'green',
        })
        refetchUsers()
        closeTOSModal()
      },
      onError: (error) => {
        notifications.show({
          title: 'Error',
          message: error.message,
          color: 'red',
        })
        refetchUsers()
      },
    })
  }

  const handleLogout = () => {
    if (!userData?.user.id) {
      return
    }
    onLogout(userData?.user.id)
  }

  const modalShouldOpen = useMemo(() => {
    if (userLoading || tosCurrentLoading || !userData || !tosCurrentData) {
      return null
    }

    if (currentUser?.impersonator) {
      return false
    }

    if (tosCurrentLoading || userLoading) {
      return false
    }

    if (!userData?.user.acceptedTerms) {
      return true
    }

    if (userAcceptedTOSVersion !== currentTOSVersion) {
      return true
    }

    return false
  }, [
    currentTOSVersion,
    currentUser?.impersonator,
    tosCurrentData,
    tosCurrentLoading,
    userAcceptedTOSVersion,
    userData,
    userLoading,
  ])

  useEffect(() => {
    if (modalShouldOpen) {
      open()
    } else {
      close()
    }
  }, [close, modalShouldOpen, open])

  // Guards for if user or tos version is still loading
  if (userLoading || tosCurrentLoading || !userData || !tosCurrentData) {
    return null
  }

  // Guards for if user is impersonating
  if (currentUser?.impersonator) {
    return null
  }

  return (
    <>
      <Modal
        fullScreen
        opened={debouncedOpened}
        withCloseButton={false}
        onClose={() => handleLogout()}
        transitionProps={{
          transition: 'slide-up',
        }}
      >
        <Center px={6}>
          <Stack pt={12} gap="lg">
            <Title order={2}>SwayDM Terms and Conditions have changed</Title>
            <Blockquote
              icon={<FontAwesomeIcon icon={faCircleInfo} size="xl" />}
            >
              Before you can proceed you must read and accept the new Terms &
              Conditions
            </Blockquote>
            {content && (
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </TypographyStylesProvider>
            )}
            <Text>
              By clicking "Accept", you agree to the Terms of Service and
              Privacy Policy.
            </Text>
            <Divider />
            <Flex justify="end" gap="lg">
              <Button variant="outline" onClick={handleLogout}>
                Cancel
              </Button>
              <Button onClick={accept} loading={pendingTOSAccept}>
                Accept
              </Button>
            </Flex>
          </Stack>
        </Center>
      </Modal>
    </>
  )
}
