import {
  AnswerStreakStatus,
  useChallengesEventSubscription,
  useChallengesOverviewLazyQuery,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { rem, useMantineTheme } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@util/notifications/notifications'
import { CompletedAnswerStreakModalContent } from './CompletedAnswerSreakModalContent'

export function ChallengesNotifications() {
  const isMobileViewport = useIsMobileViewport()
  const theme = useMantineTheme()

  const [getChallengesData] = useChallengesOverviewLazyQuery({
    variables: {
      lookback: 6,
    },
  })

  useChallengesEventSubscription({
    fetchPolicy: 'no-cache',
    onData({ data: challengeData }) {
      const challengeEvent = challengeData?.data?.challengeEvents

      if (!challengeEvent) return

      if (challengeEvent?.__typename === 'AnswerStreakUpdatedEvent') {
        const { status } = challengeEvent

        getChallengesData().then(({ data }) => {
          if (!data?.challengeAnswerStreaks.progress.state) return
          if (
            data.challengeAnswerStreaks.progress.state.__typename !==
            'ProgressTriviaAnswerStreaks'
          )
            return

          switch (status) {
            case AnswerStreakStatus.AnswerStreakStarted: {
              notifications.show({
                title: 'Answer Streak Started',
                message: 'You have started an answer streak',
                icon: '🏃',
                position: 'top-center',
              })
              break
            }
            case AnswerStreakStatus.AnswerStreakIncremented: {
              const currentStreak =
                data.challengeAnswerStreaks.progress.state.currentStreak

              notifications.show({
                title: 'Answer Streak ',
                message: `You're on a ${currentStreak} day streak!`,
                icon: '🔥',
                position: 'top-center',
              })
              break
            }
            case AnswerStreakStatus.AnswerStreakCompleted: {
              const goal = data.challengeAnswerStreaks.progress.state.goal
              const swayCashReward =
                data.challengeAnswerStreaks.progress.state.swayCashReward

              if (goal == null || swayCashReward == null) return

              modals.open({
                fullScreen: isMobileViewport,
                centered: true,
                children: (
                  <CompletedAnswerStreakModalContent
                    goal={goal}
                    swayCashReward={swayCashReward}
                    onClose={() => modals.closeAll()}
                  />
                ),
                styles: {
                  body: {
                    height: !isMobileViewport
                      ? '80vh'
                      : `calc(100vh - ${rem(60)} - var(safe-area-inset-bottom))`,
                  },
                  header: {
                    backgroundColor: theme.colors.swurple[3],
                    color: theme.white,
                  },
                  content: {
                    backgroundColor: theme.colors.swurple[3],
                    color: theme.white,
                  },
                },
              })
              break
            }
          }
        })
      }
    },
  })

  return <></>
}
