import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import CapacitorStorage from './capacitorStorage'

type State = {
  leaderboardsPageVisited: boolean
  swayCashLeaderboardAlertDismissed: boolean
  triviaLeaderboardAlertDismissed: boolean
}

type Action = {
  setLeaderboardsPageVisited: (bool: boolean) => void
  setSwayCashLeaderboardAlertDismissed: (bool: boolean) => void
  setTriviaLeaderboardAlertDismissed: (bool: boolean) => void
}

type LeaderboardsStore = State & Action

export const useLeaderboardsStore = create(
  persist<LeaderboardsStore>(
    (set) => ({
      leaderboardsPageVisited: false,
      setLeaderboardsPageVisited: (bool: boolean) => {
        set({ leaderboardsPageVisited: bool })
      },
      swayCashLeaderboardAlertDismissed: false,
      setSwayCashLeaderboardAlertDismissed: (bool: boolean) => {
        set({ swayCashLeaderboardAlertDismissed: bool })
      },
      triviaLeaderboardAlertDismissed: false,
      setTriviaLeaderboardAlertDismissed: (bool: boolean) => {
        set({ triviaLeaderboardAlertDismissed: bool })
      },
    }),
    {
      name: 'leaderboards-store',
      storage: createJSONStorage(() => CapacitorStorage),
    }
  )
)
