import { ScrollAreaAutosize, Spoiler } from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'
import { useState } from 'react'

export function FeedCardShowMore({ children }: { children: React.ReactNode }) {
  const [expanded, setExpanded] = useState(false)
  const ref = useClickOutside(() => setExpanded(false))

  return (
    <ScrollAreaAutosize mah={200} ref={ref}>
      <Spoiler
        maxHeight={28}
        showLabel="Show more"
        expanded={expanded}
        onExpandedChange={setExpanded}
        hideLabel="Hide"
        onClick={() => expanded && setExpanded(false)}
        styles={{
          control: {
            color: 'white',
            fontSize: '14px',
            textDecoration: 'underline',
          },
        }}
      >
        {children}
      </Spoiler>
    </ScrollAreaAutosize>
  )
}
