import {
  AttachedImageCombined,
  AttachedMedia,
} from '@context/mediaContext/MediaTypes.types'
import { useEffect, useState } from 'react'

interface Dimensions {
  width: number | null
  height: number | null
}

const useFetchImageDimensions = (
  attachedMediaEntry: AttachedMedia,
  mediaPointerData: any
) => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: null,
    height: null,
  })

  useEffect(() => {
    const fetchImageDimensions = async () => {
      if (attachedMediaEntry) {
        const originalImage = (attachedMediaEntry as AttachedImageCombined)
          .original

        setDimensions({
          width: originalImage.width,
          height: originalImage.height,
        })
      } else if (mediaPointerData) {
        const img = new window.Image()

        img.onload = () => {
          setDimensions({ width: img.width, height: img.height })
        }

        img.onerror = () => {
          console.error('Failed to load image')
        }

        img.src = mediaPointerData.mediaPointer.original?.url || ''
      }
    }

    fetchImageDimensions()
  }, [attachedMediaEntry, mediaPointerData])

  return dimensions
}

export default useFetchImageDimensions
