import { Text } from '@mantine/core'

import { SwayCashIcon } from '@icons/SwayCash'
import { useEffect, useRef, useState } from 'react'
import { useCountUp } from 'react-countup'

export function WalletBalanceDisplay({
  size,
  walletBalance,
}: {
  size?: 'sm' | 'md' | 'lg'
  walletBalance: number
}) {
  const countUpRef = useRef(null)
  const [initialBalance] = useState(walletBalance)

  const { start, update } = useCountUp({
    decimals: 2,
    duration: 0.5,
    end: initialBalance / 100,
    ref: countUpRef,
    start: initialBalance / 100,
  })

  useEffect(
    function updateFooterWalletBalance() {
      update(walletBalance / 100)
    },
    [update, walletBalance]
  )

  useEffect(
    function startCountUp() {
      start()
    },
    [start]
  )

  return (
    <Text
      span
      size={size || 'sm'}
      className="inline-flex items-center"
      fw={700}
    >
      <SwayCashIcon className="mr-2 h-4 w-4" />
      <Text span ref={countUpRef} fw={700} pr={4} />
      SC
    </Text>
  )
}
