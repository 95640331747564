import { Box, Group, Text, TextProps } from '@mantine/core'
import { BOLD } from '@util/utils'

interface TiltedCardProps extends TextProps {
  backgroundOne?: string
  backgroundTwo?: string
  children: React.ReactNode
}

export function TiltedCard({
  backgroundOne = 'yellow.3',
  backgroundTwo = 'navy',
  children,
  ...props
}: TiltedCardProps) {
  return (
    <Box
      p={2}
      my={4}
      bg={backgroundOne}
      className="z-10 rounded-md"
      style={{ transform: 'rotate(-3deg)' }}
    >
      <Group
        bg={backgroundTwo}
        p={10}
        align="center"
        className="rounded-md"
        style={{ transform: 'rotate(3deg)' }}
      >
        <Text fw={BOLD} {...props}>
          {children}
        </Text>
      </Group>
    </Box>
  )
}
