import { CameraPermissionState } from '@capacitor-mlkit/barcode-scanning'
import useAppContext from '@context/appContext/useAppContext'
import { useCameraContext } from '@context/cameraContext'
import { useFeatureFlags } from '@context/index'
import {
  faBullhorn as faBullhornSolid,
  faHome as faHomeSolid,
  faStore as faStoreSolid,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBullhorn,
  faHome,
  faStore,
  faTrophy,
} from '@fortawesome/sharp-regular-svg-icons'
import { faTrophy as faTrophySolid } from '@fortawesome/sharp-solid-svg-icons'
import { ProfileType, useWalletBalanceQuery } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box, Center, Group, Indicator, rem, Stack, Text } from '@mantine/core'
import { useLeaderboardsStore } from '@stores/useLeaderboardsStore'
import { isShortLink, resolveAndNavigate, trackUri } from '@util/linkUtils'
import { notifications } from '@util/notifications/notifications'
import {
  isAllowedDomain,
  isRootApiDomain,
  isRootLinkDomain,
  removeDomain,
} from '@util/urlFuncs'
import { SEMI_BOLD } from '@util/utils'
import { useCallback } from 'react'
import { Link, NavLink, useNavigate } from 'react-router'
import { QrCodeScanButton } from './QrCodeScanButton'
import { WalletBalanceDisplay } from './WalletBalanceDisplay'

export function SwayFooter() {
  const isMobile = useIsMobileViewport()
  const { data: walletBalanceData } = useWalletBalanceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const walletBalance = walletBalanceData?.wallet?.balance.amount

  const { scanSingleBarcode, checkPermissions, requestPermissions } =
    useCameraContext()

  const handleQRCodeScan = useCallback(async () => {
    let permissions: CameraPermissionState = await checkPermissions()
    if (permissions === 'prompt') {
      permissions = await requestPermissions()
    }

    if (permissions === 'granted' || permissions === 'limited') {
      const barcode = await scanSingleBarcode()
      if (barcode.format === 'QR_CODE') {
        if (!isAllowedDomain(barcode.displayValue)) {
          notifications.show({
            title: 'Invalid QR Code',
            message: 'Please scan a valid SwayDM QR code',
            color: 'red',
          })
        } else {
          const barcodeUrl = new URL(barcode.displayValue)
          if (isRootApiDomain(barcodeUrl)) {
            // This is a legacy case for vendors that may still have QR codes such as: https://sway.dm/VendorName
            navigate('/profile/' + removeDomain(barcodeUrl))
          } else if (isRootLinkDomain(barcodeUrl) && isShortLink(barcodeUrl)) {
            // If the QR code is a short link, resolve it and navigate accordingly
            resolveAndNavigate(barcodeUrl, navigate)
          } else {
            // For all other cases, strip the domain and navigate to the path
            trackUri(barcodeUrl.toString())
            navigate(removeDomain(barcodeUrl))
          }
        }
      }
    } else {
      notifications.show({
        title: 'Camera access denied',
        message: 'You can turn it on in your phone settings',
      })
    }
  }, [checkPermissions, navigate, requestPermissions, scanSingleBarcode])

  return (
    <>
      {isMobile && (
        <Group h="100%" justify="space-around" pb={16}>
          {currentUser?.profileType === ProfileType.CommunityFollower && (
            <MemberMobileNavbar
              walletBalance={walletBalance}
              onQrCodeScan={handleQRCodeScan}
            />
          )}
          {currentUser?.profileType === ProfileType.Community && (
            <VendorMobileNavbar
              walletBalance={walletBalance}
              onQrCodeScan={handleQRCodeScan}
            />
          )}
        </Group>
      )}
    </>
  )
}

function MemberMobileNavbar({
  walletBalance,
  onQrCodeScan,
}: {
  walletBalance: number | undefined | null
  onQrCodeScan: () => void
}) {
  const { deviceInfo } = useAppContext()
  const { currentUser } = useAuth()

  const leaderboardsPageVisited = useLeaderboardsStore(
    (state) => state.leaderboardsPageVisited
  )

  const memberLinks = [
    {
      to: '/feed',
      activeIcon: <FontAwesomeIcon icon={faHomeSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faHome} size="lg" />,
    },
    {
      to: '/redemptions',
      activeIcon: <FontAwesomeIcon icon={faStoreSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faStore} size="lg" />,
    },
    {
      to: '/challenges',
      activeIcon: <FontAwesomeIcon icon={faTrophySolid} size="lg" />,
      inactiveIcon: (
        <Indicator disabled={leaderboardsPageVisited} processing>
          <FontAwesomeIcon icon={faTrophy} size="lg" />
        </Indicator>
      ),
    },
  ]

  return (
    <Stack w="100%" gap={0}>
      {walletBalance !== undefined && walletBalance !== null && currentUser && (
        <Group
          wrap="nowrap"
          justify="space-between"
          px={rem(12)}
          py={4}
          className="border-b"
          gap={4}
        >
          <FooterBalanceDisplay
            currentUser={currentUser}
            walletBalance={walletBalance}
          />
          {deviceInfo?.platform !== 'web' && onQrCodeScan && (
            <QrCodeScanButton onQrCodeScan={onQrCodeScan} />
          )}
        </Group>
      )}
      <Group w="100%" justify="space-around" mt={12}>
        {memberLinks.map((link, index) => (
          <NavLink to={link.to} key={`footer-link-${index}`}>
            {({ isActive }) => (
              <Center>
                <>{isActive ? link.activeIcon : link.inactiveIcon}</>
              </Center>
            )}
          </NavLink>
        ))}
      </Group>
    </Stack>
  )
}

function VendorMobileNavbar({
  walletBalance,
  onQrCodeScan,
}: {
  walletBalance: number | undefined | null
  onQrCodeScan: () => void
}) {
  const { deviceInfo } = useAppContext()
  const { vendorDashboard } = useFeatureFlags()
  const { currentUser } = useAuth()

  const maybeDashboard = vendorDashboard && {
    to: '/vendor/dashboard',
    activeIcon: <FontAwesomeIcon icon={faHomeSolid} size="lg" />,
    inactiveIcon: <FontAwesomeIcon icon={faHome} size="lg" />,
  }

  const vendorLinks = [
    ...(maybeDashboard ? [maybeDashboard] : []),
    {
      to: '/vendor/redemptions',
      activeIcon: <FontAwesomeIcon icon={faStoreSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faStore} size="lg" />,
    },
    {
      to: '/vendor/broadcasts',
      activeIcon: <FontAwesomeIcon icon={faBullhornSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faBullhorn} size="lg" />,
    },
  ]

  return (
    <Stack w="100%" gap={0}>
      {walletBalance !== undefined && walletBalance !== null && currentUser && (
        <Group
          wrap="nowrap"
          justify="space-between"
          px={rem(12)}
          py={4}
          className="border-b"
          gap={4}
        >
          <FooterBalanceDisplay
            currentUser={currentUser}
            walletBalance={walletBalance}
          />
          {deviceInfo?.platform !== 'web' && onQrCodeScan && (
            <QrCodeScanButton onQrCodeScan={onQrCodeScan} />
          )}
        </Group>
      )}
      <Group w="100%" justify="space-around" mt={12}>
        {vendorLinks.map((link, index) => (
          <NavLink to={link.to} key={`footer-link-${index}`}>
            {({ isActive }) => {
              return (
                <Center>
                  <>{isActive ? link.activeIcon : link.inactiveIcon}</>
                </Center>
              )
            }}
          </NavLink>
        ))}
      </Group>
    </Stack>
  )
}

const FooterBalanceDisplay = ({
  currentUser,
  walletBalance,
}: {
  currentUser: {
    profileType: ProfileType
    firstName: string
    lastName: string
    communityName: string
  }
  walletBalance: number
}) => {
  const isMember = currentUser?.profileType === ProfileType.CommunityFollower
  const isVendor = currentUser?.profileType === ProfileType.Community

  return (
    <Box component={Link} to="/earn" w="100%">
      <Group wrap="nowrap" w="100%" justify="space-between">
        <Stack gap={0}>
          <Text size="xs" fw={SEMI_BOLD}>
            SwayCash Balance
          </Text>
          <Text c={'cyan.6'} fw={SEMI_BOLD} style={{ fontSize: '0.6rem' }}>
            {isMember && `${currentUser?.firstName} ${currentUser?.lastName}`}
            {isVendor && currentUser?.communityName}
          </Text>
        </Stack>
        <Group>
          <WalletBalanceDisplay walletBalance={walletBalance} />
        </Group>
      </Group>
    </Box>
  )
}
