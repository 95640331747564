import { TiltedCard } from '@components/tiltedCard/TiltedCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/sharp-duotone-solid-svg-icons'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Box,
  Button,
  Center,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core'
import { BOLD } from '@util/utils'
import React from 'react'
import ReactConfetti from 'react-confetti'

interface CompletedAnswerStreakModalContentProps {
  goal: number
  swayCashReward: number
  onClose(): void
}

export function CompletedAnswerStreakModalContent({
  goal,
  swayCashReward,
  onClose,
}: CompletedAnswerStreakModalContentProps) {
  const theme = useMantineTheme()

  return (
    <Stack justify="space-between" align="center" h="100%">
      <Box pos="absolute" top={0} left={0} w="100%" h="100%">
        <ReactConfetti
          style={{
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
          }}
          numberOfPieces={200}
        />
      </Box>

      <Center style={{ flexGrow: 1 }}>
        <Stack>
          <FontAwesomeIcon
            icon={faFire}
            style={
              {
                color: 'inherit',
                height: 120,
                '--fa-primary-color': theme.colors.white,
                '--fa-secondary-color': theme.colors['deep-blue'][9],
                '--fa-primary-opacity': 1,
                '--fa-secondary-opacity': 1,
              } as React.CSSProperties
            }
          />

          <Title ta="center" fw={BOLD} fz="120px">
            {goal}
          </Title>

          <Stack gap={4}>
            <Text ta="center" fw={BOLD} fz="35px">
              days in a row!
            </Text>
            <Text ta="center" fw={BOLD}>
              You answered a message every day during the past week. Keep it
              going!
            </Text>
          </Stack>

          <Group justify="center">
            <TiltedCard
              backgroundOne={theme.colors.orange[3]}
              ta="center"
              c="white"
            >
              You earned a bonus of{' '}
              <SwayCashIcon className="ml-1 inline-block h-4 w-4" />{' '}
              {(swayCashReward / 100).toFixed(2)} SC
            </TiltedCard>
          </Group>
        </Stack>
      </Center>

      <Button fullWidth color="swurple.9" onClick={onClose}>
        Continue to Feed
      </Button>
    </Stack>
  )
}
