import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import ImageCropperModal from '@components/media/ImageCropperModal'
import { MediaFocalPoint } from '@components/media/Media.types'
import { handleCropComplete } from '@components/media/MediaUtils'
import { SwayLoadingOverlay } from '@components/swayStates/SwayLoadingOverlay'
import useUploadMediaContext from '@context/mediaContext/useUploadMediaContext'
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CommunityDiscoveryQueryAttributes,
  MediaType,
  MoneyInput,
  PageFilterOp,
  PublicVendor,
  RedemptionDonationProgress,
  RedemptionLimitInput,
  RedemptionLimitPartsFragment,
  RedemptionLimitScope,
  RedemptionLimitType,
  RedemptionLimitUnit,
  useCommunitiesDiscoveryQuery,
  useMediaPointerLazyQuery,
  useRedemptionUpdateMutation,
  useUserQuery,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import useFetchImageDimensions from '@hooks/useFetchImageDimensions'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Group,
  Image,
  Modal,
  NumberInput,
  Popover,
  Progress,
  Stack,
  Switch,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core'
import { useField, useForm, zodResolver } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import * as Sentry from '@sentry/react'
import { generateImgixOptions } from '@util/imgixUtils'
import { notifications } from '@util/notifications/notifications'
import { findLimit, findLimitIndex } from '@util/redemptionsUtils'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Point } from 'react-easy-crop'
import { useNavigate } from 'react-router'
import { z } from 'zod'
import {
  DonationGoalInput,
  ExpirationDateLimitInput,
  MemberLimitQuantityInput,
  MemberRateLimitInput,
  TotalLimitQuantityInput,
} from './EditRedemptionFormInputs'
import { RedemptionDonationSection } from './RedemptionDonationSection'
import { RedemptionHorizontalCard } from './RedemptionHorizontalCard'
import { RedemptionMainPreview } from './RedemptionMainPreview'

const MIN_DESCRIPTION_SIZE = 20
const MAX_DESCRIPTION_SIZE = 1000

const redemptionFormValidation = z
  .object({
    amountInCents: z.object({
      currency: z.string(),
      amount: z.number().int().min(1),
    }),
    important: z.boolean().optional(),
    description: z.string().min(MIN_DESCRIPTION_SIZE).max(MAX_DESCRIPTION_SIZE),
    name: z.string().min(1).max(50),
    primaryMediaId: z.string().optional(),
    linkedProfileId: z.string().optional(),
    limits: z.array(
      z.object({
        limitType: z.enum([
          RedemptionLimitType.Count,
          RedemptionLimitType.RateLimit,
        ]),
        limitScope: z.enum([
          RedemptionLimitScope.Global,
          RedemptionLimitScope.User,
        ]),
        limit: z.number().int().min(1),
      })
    ),
  })
  .superRefine((data, ctx) => {
    // Custom validations for limits

    const globalLimit = findLimit(
      data.limits,
      RedemptionLimitType.Count,
      RedemptionLimitScope.Global
    )
    const userLimit = findLimit(
      data.limits,
      RedemptionLimitType.Count,
      RedemptionLimitScope.User
    )

    const userLimitIndex = findLimitIndex(
      data.limits,
      RedemptionLimitType.Count,
      RedemptionLimitScope.User
    )

    if (globalLimit?.limit && userLimit?.limit) {
      if (userLimit.limit > globalLimit.limit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Member limit cannot exceed global limit',
          path: ['limits', userLimitIndex, 'limit'],
        })
      }
    }
  })

export type EditRedemptionFormShape = {
  id: string
  amountInCents: MoneyInput | undefined
  important: boolean | null | undefined
  description: string | undefined
  limits: Array<RedemptionLimitInput>
  name: string | undefined
  primaryMediaId: string | undefined
  validEnd: Date | undefined
  linkedProfileId: string | undefined
  donationGoal: number | string | undefined
  spotlight: boolean | undefined
}

type EditRedemptionFormProps = {
  initialValues: EditRedemptionFormShape | undefined
  redemptionLoading: boolean
}

export function EditRedemptionForm({
  initialValues,
  redemptionImage,
  redemptionLoading,
}: EditRedemptionFormProps & {
  redemptionImage?: {
    src: string
    srcSet: string
  }
}) {
  const { currentUser } = useAuth()
  const isImpersonating = !!currentUser?.impersonator

  const { data: userProfileData } = useUserQuery({
    skip: !currentUser,
  })
  const [mediaFocalPoint, setMediaFocalPoint] = useState<
    MediaFocalPoint | undefined
  >(undefined)

  const [crop, setCrop] = useState<Point>({ x: 0.5, y: 0.5 })
  const [zoom, setZoom] = useState(1)

  const isProfileNonProfit = !!userProfileData?.user.profile?.isNonProfit

  const navigate = useNavigate()
  const { attachedMedia, clearAttachedMedia, selectMedia } =
    useUploadMediaContext()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [popoverOpened, { open, close }] = useDisclosure(false, {
    onOpen: () => {
      setTimeout(() => {
        close()
      }, 3000)
    },
  })
  const [imageModalOpened, { open: imageModalOpen, close: imageModalClose }] =
    useDisclosure(false)

  const form = useForm<EditRedemptionFormShape>({
    initialValues: initialValues || {
      id: '',
      amountInCents: {
        currency: 'USD',
        amount: 1.0,
      },
      limits: [],
      important: false,
      description: '',
      name: '',
      primaryMediaId: undefined,
      validEnd: undefined,
      linkedProfileId: undefined,
      donationGoal: undefined,
      spotlight: undefined,
    },
    validate: zodResolver(redemptionFormValidation),
    onValuesChange(values, previous) {
      if (
        previous.amountInCents?.amount !== values.amountInCents?.amount &&
        showNonProfitSectionField.getValue()
      ) {
        // Update the donation goal based on the new cost but preserving the same global limit
        const globalLimit = findLimit(
          values.limits,
          RedemptionLimitType.Count,
          RedemptionLimitScope.Global
        )

        if (globalLimit && form.isTouched('amountInCents.amount')) {
          // Open the popover if the donation goal is set
          open()

          const newDonationGoal =
            (globalLimit.limit as number) *
            (values.amountInCents?.amount as number)

          form.setFieldValue('donationGoal', newDonationGoal)
        }
      }
    },
  })

  const showNonProfitSectionField = useField({
    type: 'checkbox',
    initialValue: isProfileNonProfit || !!form.values.linkedProfileId,
    onValueChange: (value) => {
      if (!value) {
        form.setValues({
          linkedProfileId: undefined,
          donationGoal: undefined,
        })
      }
    },
  })

  const { data: communitiesData } = useCommunitiesDiscoveryQuery({
    variables: {
      query: {
        filters: [
          {
            attribute: CommunityDiscoveryQueryAttributes.IsNonProfit,
            op: PageFilterOp.Eq,
            value: {
              boolean: true,
            },
          },
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: isProfileNonProfit,
  })

  const [
    getMediaPointer,
    {
      data: mediaPointerData,
      error: errorMediaPointer,
      loading: loadingMediaPointer,
    },
  ] = useMediaPointerLazyQuery()

  const [localMediaPointerData, setLocalMediaPointerData] =
    useState(mediaPointerData)

  useEffect(() => {
    if (!mediaPointerData || errorMediaPointer || loadingMediaPointer) return
    setLocalMediaPointerData(mediaPointerData)
  }, [mediaPointerData, errorMediaPointer, loadingMediaPointer])

  const clearMediaPointerData = () => {
    setLocalMediaPointerData(undefined)
  }

  const dimensions = useFetchImageDimensions(attachedMedia[0], mediaPointerData)

  const handleGetMediaPointer = () => {
    getMediaPointer({
      variables: {
        mediaPointerId:
          form.values.primaryMediaId || attachedMedia[0].mediaPointerId,
        optsOriginal: generateImgixOptions({
          fit: 'crop',
          'fp-x': 0.5,
          'fp-y': 0.5,
          'fp-z': 1,
          auto: 'compress',
          ar: undefined,
        }),
        opts9by16: generateImgixOptions({
          w: 360,
          h: 640,
          fit: 'crop',
          auto: 'compress',
          ...(mediaFocalPoint && {
            'fp-x': mediaFocalPoint.x,
            'fp-y': mediaFocalPoint.y,
            'fp-z': mediaFocalPoint.z,
          }),
          crop: 'focalpoint',
          ar: '9:16',
        }),
        opts5by3: generateImgixOptions({
          w: 500,
          h: 300,
          fit: 'crop',
          auto: 'compress',
          ...(mediaFocalPoint && {
            'fp-x': mediaFocalPoint.x,
            'fp-y': mediaFocalPoint.y,
            'fp-z': mediaFocalPoint.z,
          }),
          crop: 'focalpoint',
          ar: '5:3',
        }),
        opts1by1: generateImgixOptions({
          w: 256,
          h: 256,
          fit: 'crop',
          auto: 'compress',
          ...(mediaFocalPoint && {
            'fp-x': mediaFocalPoint.x,
            'fp-y': mediaFocalPoint.y,
            'fp-z': mediaFocalPoint.z,
          }),
          crop: 'focalpoint',
          ar: '1:1',
        }),
      },
    })
  }

  const nonProfitCommunities = communitiesData?.discoverCommunities?.data.map(
    (entry) => ({
      label: entry.vendor.name || entry.vendor.displayName,
      value: entry.vendor.id,
      image: entry.vendor.photoUrl,
    })
  )

  const [updateRedemption, { loading: updateRedemptionLoading }] =
    useRedemptionUpdateMutation()

  const handleUpdateRedemption = async (goLive = false) => {
    const valid = form.validate()

    if (valid.hasErrors) {
      notifications.show({
        title: 'Failed to update redemption',
        message: 'Please check the redemption form for errors',
        color: 'red',
      })

      if (valid.errors && Object.keys(valid.errors).length > 0) {
        const elementWithError = document.querySelector(
          `#${Object.keys(valid.errors)[0]}`
        )

        if (elementWithError) {
          elementWithError.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }

      return
    }

    if (
      form.values.name &&
      form.values.description &&
      form.values.amountInCents
    ) {
      try {
        const result = await updateRedemption({
          variables: {
            redemptionId: form.values.id,
            redemption: {
              active: goLive,
              amountInCents: {
                amount: form.values.amountInCents.amount * 100,
                currency: form.values.amountInCents.currency,
              },
              limits: [...form.values.limits],
              important: form.values.important,
              description: form.values.description,
              name: form.values.name,
              primaryMediaId: form.values.primaryMediaId,
              validEnd: form.values.validEnd?.toISOString() || null,
              // If the user is a non-profit and the non-profit toggle is enabled, we want to link the redemption to the user's profile
              // Otherwise, we want to link it to the selected non-profit profile
              linkedProfileId:
                showNonProfitSectionField.getValue() && isProfileNonProfit
                  ? currentUser?.id
                  : form.values.linkedProfileId || null,
              primaryMediaDefaultMediaOptions:
                form.values.primaryMediaId && mediaFocalPoint
                  ? generateImgixOptions({
                      'fp-x': mediaFocalPoint.x,
                      'fp-y': mediaFocalPoint.y,
                      'fp-z': mediaFocalPoint.z,
                      fit: 'crop',
                      crop: 'focalpoint',
                      ar: '5:3',
                    })
                  : null,
              spotlight: form.values.spotlight,
            },
          },
        })

        if (result.errors || !result.data?.redemptionUpdate.successful) {
          notifications.show({
            title: 'Failed to update redemption',
            message: 'Please check the redemption form for errors',
          })
          return
        }

        if (goLive) {
          notifications.show({
            title: 'Redemption is now live',
            message: 'Redemption is now live and available to members',
          })
          navigate('/vendor/redemptions')
        } else {
          notifications.show({
            title: 'Redemption updated',
            message: 'Redemption has been updated',
          })
          navigate('/vendor/redemptions/drafts')
        }
      } catch (error) {
        notifications.show({
          title: 'Failed to update redemption',
          message: "We couldn't update the redemption",
        })
        Sentry.captureException(error, {
          extra: {
            query: 'REDEMPTION_UPDATE',
          },
        })
      }
    }
  }

  useEffect(
    function handleInitialValues() {
      if (initialValues) {
        form.setValues({
          ...initialValues,
          amountInCents: {
            currency: 'USD',
            amount: initialValues.amountInCents?.amount
              ? initialValues.amountInCents.amount / 100
              : 0.0,
          },
          validEnd: initialValues.validEnd,
        })
      }

      const globalLimit = findLimit(
        initialValues?.limits,
        RedemptionLimitType.Count,
        RedemptionLimitScope.Global
      )

      if (initialValues?.linkedProfileId && globalLimit) {
        form.setFieldValue(
          'donationGoal',
          (globalLimit?.limit as number) *
            ((form.values.amountInCents?.amount as number) / 100)
        )
      }

      showNonProfitSectionField.setValue(!!initialValues?.linkedProfileId)
    },
    // We only want to have this effect run when the initial values changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues]
  )

  useEffect(
    function handleUpdatingFormWithAttachedMediaPointer() {
      if (
        form.values.primaryMediaId ||
        (attachedMedia && attachedMedia.length > 0)
      ) {
        form.setValues({
          ...form.values,
          primaryMediaId:
            form.values.primaryMediaId || attachedMedia[0].mediaPointerId,
        })

        handleGetMediaPointer()
      }
    },
    // We only want to have this effect run when the attachedMedia changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachedMedia]
  )

  const hasLocallyAttachedMedia = attachedMedia && attachedMedia.length > 0
  const locallyAttachedMediaIsUploading =
    hasLocallyAttachedMedia && attachedMedia[0].uploadProgress < 100
  const hasPrimaryMedia =
    form.values.primaryMediaId && redemptionImage && !hasLocallyAttachedMedia

  const attachedMediaThumbnail =
    hasLocallyAttachedMedia && attachedMedia[0].mediaType === MediaType.Image
      ? attachedMedia[0].original.localUrl
      : undefined

  function addLimit(
    type: RedemptionLimitType,
    scope: RedemptionLimitScope,
    opts: {
      unit?: RedemptionLimitUnit
      interval?: number
      limit?: number
    } = { limit: 1 }
  ) {
    const { unit, interval, limit } = opts

    form.setValues({
      ...form.values,
      limits: [
        ...form.values.limits,
        {
          limitType: type,
          limitScope: scope,
          unit,
          limit,
          interval,
          // If the limit is per-period, we want to set endOfDay to true because all available units by now are >= day
          ...(RedemptionLimitType.RateLimit === type && { endOfDay: true }),
        },
      ],
    })
  }

  function removeLimit(type: RedemptionLimitType, scope: RedemptionLimitScope) {
    const indexOfLimitToRemove = findLimitIndex(form.values.limits, type, scope)

    if (indexOfLimitToRemove > -1) {
      form.setValues({
        ...form.values,
        limits: [
          ...form.values.limits.slice(0, indexOfLimitToRemove),
          ...form.values.limits.slice(indexOfLimitToRemove + 1),
        ],
      })
    }
  }

  const handleDonationGoalOnBlur = () => {
    const amount = form.values.amountInCents?.amount as number
    const donationGoal = form.values.donationGoal

    if (!donationGoal || !amount) return

    const adjustedValue = Math.ceil((donationGoal as number) / amount) * amount

    let limitUpdated = false
    const updatedLimits = form.values.limits.map((limit) => {
      if (
        limit.limitType === RedemptionLimitType.Count &&
        limit.limitScope === RedemptionLimitScope.Global
      ) {
        limitUpdated = true
        return {
          ...limit,
          limit: adjustedValue / amount,
        }
      }

      return limit
    })

    if (!limitUpdated) {
      updatedLimits.push({
        limitType: RedemptionLimitType.Count,
        limitScope: RedemptionLimitScope.Global,
        limit: adjustedValue / amount,
      })
    }

    form.setValues({
      limits: updatedLimits,
      donationGoal: adjustedValue as number,
    })
  }

  const previewDonationProgress = (): RedemptionDonationProgress => {
    const limitEntry = findLimit(
      form.values.limits,
      RedemptionLimitType.Count,
      RedemptionLimitScope.Global
    )

    if (limitEntry) {
      const goalAmount =
        (limitEntry.limit as number) *
        (form.values.amountInCents?.amount as number) *
        100

      return {
        goal: {
          amount: goalAmount,
          currency: 'USD',
        },
        current: {
          amount: 0,
          currency: 'USD',
        },
        progress: 0,
      }
    } else {
      return {
        goal: undefined,
        current: { amount: 0, currency: 'USD' },
        progress: undefined,
      }
    }
  }

  const selectedLinkedProfile = communitiesData?.discoverCommunities?.data.find(
    (entry: any) => entry.vendor.id === form.values.linkedProfileId
  )?.vendor

  // Return limit props from the form based on the `scope` and `type`.
  const getLimitProps = (
    type: RedemptionLimitType,
    scope: RedemptionLimitScope,
    fieldName = 'limit'
  ) => {
    const limitIndex = findLimitIndex(form.values.limits, type, scope)

    return {
      value: form.getInputProps(`limits.${limitIndex}.${fieldName}`).value,
      onChange: form.getInputProps(`limits.${limitIndex}.${fieldName}`)
        .onChange,
    }
  }

  return (
    <>
      <Box pos="relative" w="100%">
        <SwayLoadingOverlay visible={redemptionLoading} />
        <form>
          <Stack mb="xl">
            {isImpersonating && (
              <Stack>
                <InputLabelSectionHeader>Admin Options</InputLabelSectionHeader>
                <Switch
                  label="Spotlight"
                  description="Marks this redemption as a spotlight"
                  {...form.getInputProps('spotlight', { type: 'checkbox' })}
                />
                <Switch
                  label="Important"
                  description="Prioritizes this redemption in the feed"
                  {...form.getInputProps('important', { type: 'checkbox' })}
                />
              </Stack>
            )}
            <TextInput
              label={
                <InputLabelSectionHeader>
                  Redemption Name
                </InputLabelSectionHeader>
              }
              description="Add a short, descriptive title to attract Members"
              placeholder="Enter Redemption Name"
              id="name"
              required
              {...form.getInputProps('name')}
            />
            <Box>
              <Textarea
                id="description"
                placeholder="Start typing..."
                label={
                  <InputLabelSectionHeader>Description</InputLabelSectionHeader>
                }
                required
                autosize
                minRows={2}
                maxRows={5}
                {...form.getInputProps('description')}
              />
              <Group justify="flex-end" mt={4} align="flex-start">
                <Text
                  size="xs"
                  c={
                    (form.values.description?.length || 0) <
                    MAX_DESCRIPTION_SIZE * 0.8
                      ? 'gray'
                      : 'red'
                  }
                >
                  {form.values.description?.length || 0} /{' '}
                  {MAX_DESCRIPTION_SIZE}
                </Text>
              </Group>
            </Box>
            <Popover
              width={300}
              withArrow
              position="bottom"
              opened={popoverOpened}
            >
              <Popover.Target>
                <NumberInput
                  label={
                    <InputLabelSectionHeader>Cost</InputLabelSectionHeader>
                  }
                  placeholder="Cost in SwayCash"
                  leftSection={<SwayCashIcon className="h-4 w-4" />}
                  required
                  step={1}
                  allowNegative={false}
                  allowDecimal={false}
                  min={1}
                  {...form.getInputProps('amountInCents.amount')}
                />
              </Popover.Target>
              <Popover.Dropdown
                style={{ background: '#000065', color: 'white' }}
              >
                <Text size="xs">Donation goal has been updated</Text>
              </Popover.Dropdown>
            </Popover>

            <Box>
              <InputLabelSectionHeader>Donations</InputLabelSectionHeader>
              <Stack>
                <Switch
                  label="Enable donation options"
                  defaultChecked={!!initialValues?.linkedProfileId}
                  {...showNonProfitSectionField.getInputProps()}
                  onChange={(event) => {
                    if (!event.currentTarget.checked) {
                      removeLimit(
                        RedemptionLimitType.Count,
                        RedemptionLimitScope.Global
                      )
                    }
                    showNonProfitSectionField.getInputProps().onChange(event)
                  }}
                />
                {showNonProfitSectionField.getValue() && (
                  <DonationGoalInput
                    nonProfitCommunities={nonProfitCommunities}
                    isProfileNonProfit={isProfileNonProfit}
                    handleDonationGoalOnBlur={handleDonationGoalOnBlur}
                    linkedProfileIdValue={
                      form.getInputProps('linkedProfileId').value
                    }
                    linkedProfileIdOnChange={
                      form.getInputProps('linkedProfileId').onChange
                    }
                    donationGoalValue={form.getInputProps('donationGoal').value}
                    donationGoalOnChange={
                      form.getInputProps('donationGoal').onChange
                    }
                    values={form.values}
                    setValues={form.setValues}
                  />
                )}
              </Stack>
            </Box>

            <Box>
              <InputLabelSectionHeader>Limits</InputLabelSectionHeader>
              <Group wrap="nowrap">
                <Stack>
                  <ExpirationDateLimitInput
                    values={form.values}
                    setValues={form.setValues}
                    value={form.getInputProps('validEnd').value}
                    onChange={form.getInputProps('validEnd').onChange}
                  />
                  <TotalLimitQuantityInput
                    values={form.values}
                    initialValues={initialValues}
                    showNonProfitSectionField={showNonProfitSectionField.getValue()}
                    addLimit={addLimit}
                    removeLimit={removeLimit}
                    {...getLimitProps(
                      RedemptionLimitType.Count,
                      RedemptionLimitScope.Global
                    )}
                  />
                  <MemberLimitQuantityInput
                    values={form.values}
                    initialValues={initialValues}
                    showNonProfitSectionField={showNonProfitSectionField.getValue()}
                    addLimit={addLimit}
                    removeLimit={removeLimit}
                    {...getLimitProps(
                      RedemptionLimitType.Count,
                      RedemptionLimitScope.User
                    )}
                  />
                  <MemberRateLimitInput
                    initialValues={initialValues}
                    values={form.values}
                    limitValue={
                      getLimitProps(
                        RedemptionLimitType.RateLimit,
                        RedemptionLimitScope.User
                      ).value
                    }
                    limitOnChange={
                      getLimitProps(
                        RedemptionLimitType.RateLimit,
                        RedemptionLimitScope.User
                      ).onChange
                    }
                    unitValue={
                      getLimitProps(
                        RedemptionLimitType.RateLimit,
                        RedemptionLimitScope.User,
                        'unit'
                      ).value
                    }
                    unitOnChange={
                      getLimitProps(
                        RedemptionLimitType.RateLimit,
                        RedemptionLimitScope.User,
                        'unit'
                      ).onChange
                    }
                    addLimit={addLimit}
                    removeLimit={removeLimit}
                  />
                </Stack>
              </Group>
            </Box>
            {/* Auto Confirmation toggle will go here in the future */}
            <Box>
              <Group justify="space-between">
                <InputLabelSectionHeader>Cover Image</InputLabelSectionHeader>
                <Text size="sm" c="gray.7">
                  Optional
                </Text>
              </Group>
              {!hasLocallyAttachedMedia && !hasPrimaryMedia && (
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => selectMedia()}
                  fullWidth
                >
                  Add Photo
                </Button>
              )}
              {(hasLocallyAttachedMedia || hasPrimaryMedia) && (
                <Box mt={12} pos="relative" maw={150} p={6}>
                  <Image
                    src={
                      hasPrimaryMedia
                        ? localMediaPointerData?.mediaPointer.original?.url
                        : attachedMediaThumbnail
                    }
                    h={150}
                    mah={150}
                    w={150}
                    radius="md"
                    srcSet={
                      hasPrimaryMedia
                        ? localMediaPointerData?.mediaPointer.original?.url
                        : undefined
                    }
                    alt="Redemption Image"
                  />
                  {hasLocallyAttachedMedia && (
                    <Progress.Root size="xl" pos="absolute" bottom={6} w="100%">
                      <Progress.Section
                        value={attachedMedia[0].uploadProgress}
                        animated={attachedMedia[0].uploadProgress < 100}
                        color="blue"
                      >
                        {attachedMedia[0].uploadProgress >= 100 && (
                          <Text c="white">Upload Complete</Text>
                        )}
                      </Progress.Section>
                    </Progress.Root>
                  )}
                  <ActionIcon
                    variant="filled"
                    radius="xl"
                    onClick={() => {
                      clearAttachedMedia()
                      clearMediaPointerData()
                      setMediaFocalPoint(undefined)
                      form.setValues({
                        ...form.values,
                        primaryMediaId: '',
                      })
                    }}
                    pos="absolute"
                    top={0}
                    right={0}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </ActionIcon>
                  {localMediaPointerData?.mediaPointer.original?.url &&
                    dimensions.width &&
                    dimensions.height && (
                      <ActionIcon
                        variant="filled"
                        radius="xl"
                        onClick={imageModalOpen}
                        pos="absolute"
                        top={0}
                        left={0}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </ActionIcon>
                    )}
                </Box>
              )}
            </Box>

            <Box>
              <InputLabelSectionHeader>
                Redemption Preview
              </InputLabelSectionHeader>
              <Card withBorder>
                <RedemptionMainPreview
                  vendor={{
                    name: 'Vendor Name',
                    displayName: 'vendor_name',
                  }}
                  hasExpired={dayjs().isAfter(dayjs(form.values.validEnd))}
                  image={{
                    src:
                      localMediaPointerData?.mediaPointer?.five_by_three?.url ??
                      '',
                    srcSet:
                      localMediaPointerData?.mediaPointer?.five_by_three?.url ??
                      '',
                  }}
                  limits={
                    (form.values.limits as RedemptionLimitPartsFragment[]) || []
                  }
                  name={form.values.name || ''}
                  price={{
                    amount: Number.isInteger(form.values.amountInCents?.amount)
                      ? form.values.amountInCents?.amount?.toFixed(2) || 0
                      : 0,
                  }}
                  validEnd={form.values.validEnd?.toISOString() || undefined}
                  linkedProfileId={form.values.linkedProfileId}
                  ownerProfileId={currentUser?.id || ''}
                  linkedProfilePhotoUrl={selectedLinkedProfile?.photoUrl}
                  linkedProfileDisplayName={selectedLinkedProfile?.displayName}
                />
                <Text>{form.values.description}</Text>
                {showNonProfitSectionField.getValue() && (
                  <>
                    <Divider my="1rem" />
                    <RedemptionDonationSection
                      donationProgress={previewDonationProgress()}
                      isForDetailsPage={true}
                    />
                  </>
                )}
              </Card>
              <Card withBorder mt={8}>
                <RedemptionHorizontalCard
                  id={form.values.id}
                  isImpersonating={isImpersonating}
                  important={form.values.important}
                  name={form.values.name || ''}
                  description={form.values.description || ''}
                  community={{
                    profilePhotoUrl: currentUser?.profilePictureUrl || '',
                    communityName: currentUser?.communityName || '',
                  }}
                  price={{
                    amount:
                      (form.values.amountInCents?.amount as number) * 100 || 0,
                  }}
                  redemptionMedia={{
                    __typename: 'Redemption',
                    primaryMedia: {
                      __typename: 'MediaPointer',
                      id: form.values.primaryMediaId || '',
                      proxy: {
                        __typename: 'SignedUrl',
                        url:
                          localMediaPointerData?.mediaPointer?.one_by_one
                            ?.url || '',
                      },
                    },
                  }}
                  isNonProfit={
                    isProfileNonProfit && showNonProfitSectionField.getValue()
                  }
                  donationProgress={previewDonationProgress()}
                  linkedProfile={selectedLinkedProfile as PublicVendor}
                  isExpired={false}
                  ownerProfileId={currentUser?.id || ''}
                  hasLimits={
                    (form.values.limits as RedemptionLimitPartsFragment[])
                      .length > 0
                  }
                  showSaveSection={false}
                />
              </Card>
              {localMediaPointerData && (
                <Card withBorder mt={8}>
                  <Text>Fullscreen Image Preview</Text>
                  <Image
                    src={
                      localMediaPointerData?.mediaPointer?.nine_by_sixteen
                        ?.url || ''
                    }
                    alt="Redemption Image"
                    w="180"
                    h="320"
                    radius="md"
                  />
                </Card>
              )}
            </Box>
            <Stack>
              <Button
                type="button"
                disabled={
                  redemptionLoading ||
                  updateRedemptionLoading ||
                  locallyAttachedMediaIsUploading
                }
                onClick={() => setShowConfirmationModal(true)}
              >
                Go Live
              </Button>
              <Button
                type="button"
                variant="outline"
                disabled={
                  redemptionLoading ||
                  updateRedemptionLoading ||
                  locallyAttachedMediaIsUploading
                }
                onClick={() => handleUpdateRedemption(false)}
              >
                Update Draft
              </Button>
              <Button
                type="button"
                variant="outline"
                disabled={redemptionLoading || updateRedemptionLoading}
                onClick={() => {
                  form.reset()
                  navigate('/vendor/redemptions/drafts')
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
      <Modal
        opened={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Are you sure?"
        size="md"
        centered
      >
        <Text>
          Are you sure you want this redemption to go live? This action cannot
          be undone.
        </Text>
        <Group mt={8} justify="flex-end" align="flex-end">
          <Button
            type="button"
            onClick={() => setShowConfirmationModal(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              handleUpdateRedemption(true)
              setShowConfirmationModal(false)
            }}
          >
            Go Live!
          </Button>
        </Group>
      </Modal>

      {localMediaPointerData?.mediaPointer.original?.url &&
        dimensions.width &&
        dimensions.height && (
          <ImageCropperModal
            imageModalOpened={imageModalOpened}
            imageModalClose={imageModalClose}
            attachedMediaThumbnail={
              localMediaPointerData?.mediaPointer.original?.url
            }
            crop={crop}
            setCrop={setCrop}
            zoom={zoom}
            setZoom={setZoom}
            handleCropComplete={(croppedArea, croppedAreaPixels) =>
              handleCropComplete(
                croppedArea,
                croppedAreaPixels,
                dimensions.width as number,
                dimensions.height as number,
                setMediaFocalPoint,
                zoom
              )
            }
            onSave={handleGetMediaPointer}
          />
        )}
    </>
  )
}
