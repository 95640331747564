import { useAuth } from '@hooks/useAuth'
import useDeepLinkStore from '@stores/useDeepLinkStore'
import { getPathFromShortCode, isShortLink, trackUri } from '@util/linkUtils'
import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

const UNAUTH_URL_WHITELIST = [
  '/login',
  '/register',
  '/redemptions/offers',
  '/profile',
  '/confirm',
  '/token-auth',
  '/users/reset_password',
  '/thankYou',
]

export const DeepLinkProvider = () => {
  const { currentUser } = useAuth()
  const deepLink = useDeepLinkStore((state) => state.deepLink)
  const clearDeepLink = useDeepLinkStore((state) => state.clearDeepLink)

  const navigate = useNavigate()
  const location = useLocation()

  const navigateToPath = useCallback(
    (path: string, deepLink: URL) => {
      if (!isShortLink(deepLink)) {
        trackUri(deepLink.toString())
      }
      // If we are redirecting to a redemption details page, we check if this is expired.
      // If it is, we redirect to the top live vendor redemption page.
      const redemptionId =
        path.split('/redemptions/offers/')[1]?.split('?')[0] || null

      if (path.includes('redemptions/offers') && redemptionId) {
        navigate(`/redemptions/offers/${redemptionId}/redirect`)
        clearDeepLink()
      } else {
        navigate(path)
        clearDeepLink()
      }
    },
    [clearDeepLink, navigate]
  )

  useEffect(() => {
    const handleDeepLink = async (deepLink: URL) => {
      const path = isShortLink(deepLink)
        ? // If the url is a short link, resolve it and redirect accordingly
          // Example https://link.sway.dm/s/3997_dO1gKY
          await getPathFromShortCode(deepLink)
        : // Otherwise, will redirect to the path after the domain, which includes query params.
          // Example: /profile/sonicthehedgehog?rings=23
          deepLink.pathname + deepLink.search

      const shouldNavigate = path && location.pathname !== '/'
      const isAuthenticated = currentUser?.accessToken

      if (shouldNavigate) {
        if (isAuthenticated) {
          navigateToPath(path, deepLink)
        } else {
          const isWhitelisted = UNAUTH_URL_WHITELIST.some((url) =>
            path.startsWith(url)
          )
          if (!isWhitelisted) {
            navigate('/login')
            // We don't want to clear the deep link here because we want to redirect to the deep link after the user logs in
            return
          }
          navigateToPath(path, deepLink)
        }
      }
    }

    if (deepLink) {
      handleDeepLink(deepLink)
    }
  }, [clearDeepLink, deepLink, navigate, location, currentUser, navigateToPath])

  return null
}
