import redemptionsPlaceholder from '@assets/redemptions-placeholder-square.png'
import { Skeleton } from '@components/skeleton/Skeleton'
import { faHeart as faHeartLight } from '@fortawesome/free-regular-svg-icons'
import {
  faClock,
  faHeart as faHeartSolid,
} from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faGlobe, faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  RedemptionLimitPartsFragment,
  RedemptionLimitScope,
  RedemptionLimitType,
} from '@graphql'
import { Avatar, Box, Center, Image, Text } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { cn } from '@util/utils'
import { useState } from 'react'
import { useNavigate } from 'react-router'

const avatarForLimit = (
  limit: RedemptionLimitPartsFragment,
  avatarSize: string
) => {
  switch (limit.__typename) {
    case 'RedemptionLimitRate':
      return (
        <Avatar
          color="yellow"
          size={avatarSize}
          key={`${limit.limitScope}-${limit.limitType}`}
        >
          <FontAwesomeIcon icon={faCalendar} />
        </Avatar>
      )

    case 'RedemptionLimitCount':
      return limit.limitScope == RedemptionLimitScope.User ? (
        <Avatar
          color="blue"
          size={avatarSize}
          key={`${limit.limitScope}-${limit.limitType}`}
        >
          <FontAwesomeIcon icon={faUser} />
        </Avatar>
      ) : (
        <Avatar
          color="purple"
          size={avatarSize}
          key={`${limit.limitScope}-${limit.limitType}`}
        >
          <FontAwesomeIcon icon={faGlobe} />
        </Avatar>
      )

    default:
      return <></>
  }
}

const RedemptionStatusBanner = ({
  banner,
  bg,
  isResponsive = false,
}: {
  banner: string
  bg: string
  isResponsive?: boolean
}) => {
  return (
    <Center
      pos="absolute"
      top={0}
      left={0}
      w="100%"
      h="100%"
      style={{ zIndex: 1 }}
    >
      <Box bg={bg} className="-rotate-[15deg]" px={20}>
        {isResponsive ? (
          <>
            <Text c="white" ta="center" fz="16px" visibleFrom="md">
              {banner}
            </Text>
            <Text c="white" ta="center" fz="12px" hiddenFrom="md">
              {banner}
            </Text>
          </>
        ) : (
          <Text c="white" ta="center" fz="72px">
            {banner}
          </Text>
        )}
      </Box>
    </Center>
  )
}

const LimitsAvatarGroup = ({
  limits,
  maxLimitsToShow = 3,
  totalLimitCount,
  avatarSize = 'sm',
  hasLimits,
  validEnd,
}: {
  limits: RedemptionLimitPartsFragment[]
  maxLimitsToShow?: number
  totalLimitCount: number
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg'
  hasLimits?: boolean
  validEnd?: string
}) => {
  const { hovered, ref } = useHover<HTMLDivElement>()

  // Even though `validEnd` is not a limit, we still display an avatar for it.
  const avatars = [
    ...(validEnd
      ? [
          <Avatar key="validEnd" color="cyan.6" size={avatarSize}>
            <FontAwesomeIcon icon={faClock} />
          </Avatar>,
        ]
      : []),
    ...(hasLimits
      ? limits.map((limit) => avatarForLimit(limit, avatarSize))
      : []),
  ]

  return (
    <Avatar.Group
      pos="absolute"
      spacing={hovered && totalLimitCount > 1 ? 4 : 'sm'}
      top={0}
      left={0}
      m={6}
      ref={ref as React.RefObject<HTMLDivElement>}
      className="z-10 transition-spacing duration-300"
    >
      {avatars.slice(0, maxLimitsToShow)}

      {/* If we have more than maxNormalLimitsToShow limits, show a "+" avatar */}
      {totalLimitCount > maxLimitsToShow && (
        <Avatar size={avatarSize}>+{totalLimitCount - maxLimitsToShow}</Avatar>
      )}
    </Avatar.Group>
  )
}

const RedemptionCardImage = ({
  profilePhotoOrRedemptionsPlaceholder,
  validEnd,
  limits,
  hasLimits,
  isArchived,
  isFunded,
  isExpired,
  src,
  imageMaxHeight,
  imageMaxWidth,
}: {
  profilePhotoOrRedemptionsPlaceholder: string
  validEnd: string | undefined
  limits: RedemptionLimitPartsFragment[]
  hasLimits?: boolean
  isArchived?: boolean
  isFunded?: boolean
  isExpired?: boolean
  src?: string
  imageMaxHeight?: number
  imageMaxWidth?: number
}) => {
  // The `validEnd` works as a limit, even though is not technically one.
  const totalLimitCount = limits.length + (validEnd ? 1 : 0)
  const isAvailable = !isArchived && !isExpired

  return (
    <>
      <LimitsAvatarGroup
        limits={limits}
        validEnd={validEnd}
        maxLimitsToShow={3}
        totalLimitCount={totalLimitCount}
        hasLimits={hasLimits}
      />

      {isFunded ? (
        <RedemptionStatusBanner
          bg="donation-green"
          banner="FUNDED"
          isResponsive
        />
      ) : isArchived ? (
        <RedemptionStatusBanner bg="red" banner="DISABLED" isResponsive />
      ) : isExpired ? (
        <RedemptionStatusBanner bg="swurple" banner="EXPIRED" isResponsive />
      ) : null}

      <Image
        fit="cover"
        radius="md"
        src={src}
        h={128}
        w={128}
        mah={imageMaxHeight}
        maw={imageMaxWidth}
        fallbackSrc={profilePhotoOrRedemptionsPlaceholder}
        visibleFrom="md"
        className={`${!isAvailable ? 'grayscale' : ''}`}
      />
      <Image
        fit="cover"
        radius="md"
        src={src}
        h={105}
        w={105}
        mah={imageMaxHeight}
        maw={imageMaxWidth}
        fallbackSrc={profilePhotoOrRedemptionsPlaceholder}
        hiddenFrom="md"
        className={`${!isAvailable ? 'grayscale' : ''}`}
      />
    </>
  )
}

const RedemptionDetailsImage = ({
  src,
  srcSet,
  validEnd,
  isExpired,
  isFunded,
  isArchived,
  limits,
  imageMaxHeight,
  imageMaxWidth,
}: {
  src?: string
  srcSet?: string
  validEnd?: string
  isExpired?: boolean
  isFunded?: boolean
  isArchived?: boolean
  globalLimit?: RedemptionLimitPartsFragment
  memberLimit?: RedemptionLimitPartsFragment
  intervalLimit?: RedemptionLimitPartsFragment
  limits?: RedemptionLimitPartsFragment[]
  imageMaxHeight?: number
  imageMaxWidth?: number
}) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  const isAvailable = !isArchived && !isExpired

  return (
    <>
      {isAvailable && (
        <LimitsAvatarGroup
          limits={limits as RedemptionLimitPartsFragment[]}
          validEnd={validEnd}
          maxLimitsToShow={3}
          totalLimitCount={3}
          hasLimits
          avatarSize="md"
        />
      )}

      {isFunded ? (
        <RedemptionStatusBanner bg="donation-green" banner="FUNDED" />
      ) : isArchived ? (
        <RedemptionStatusBanner bg="red" banner="DISABLED" />
      ) : isExpired ? (
        <RedemptionStatusBanner bg="swurple" banner="EXPIRED" />
      ) : null}

      {src && (
        <Box className="h-auto max-h-[200px] md:max-h-[300px]">
          {!hasLoaded && !hasError && (
            <Skeleton
              className="absolute left-0 top-0 h-full w-full rounded-md"
              style={{ zIndex: 1 }}
            />
          )}
          <Image
            src={src}
            w={500}
            h={300}
            mah={imageMaxHeight}
            maw={imageMaxWidth}
            srcSet={srcSet}
            onLoad={() => setHasLoaded(true)}
            onError={() => setHasError(true)}
            radius="md"
            fit="cover"
            className={cn(
              'block aspect-[500/300] h-auto max-h-[200px] bg-accent md:max-h-[300px]',
              !hasLoaded && 'opacity-0',
              !isAvailable && 'grayscale'
            )}
          />
        </Box>
      )}

      {(!src || hasError) && (
        <Box pos="relative" className="h-auto max-h-[200px] md:max-h-[300px]">
          <Image
            w={500}
            h={300}
            mah={imageMaxHeight}
            maw={imageMaxWidth}
            radius="md"
            fit="cover"
            className={cn(
              'block aspect-[500/300] h-auto max-h-[200px] md:max-h-[300px]',
              isAvailable && 'bg-accent',
              !isAvailable && 'grayscale'
            )}
            src={redemptionsPlaceholder}
          />
        </Box>
      )}
    </>
  )
}

export const RedemptionImage = ({
  src,
  srcSet,
  ownerProfileId,
  profilePhotoUrl,
  isExpired,
  isFunded = false,
  isForCard = false,
  limits,
  validEnd,
  linkedProfileId,
  linkedProfilePhotoUrl,
  linkedProfileDisplayName,
  showSaveSection = false,
  saved,
  hasLimits,
  imageMaxHeight,
  imageMaxWidth,
  isArchived,
  handleSaveRedemption,
  handleUnsaveRedemption,
}: {
  src: string | undefined
  srcSet: string | undefined
  ownerProfileId?: string
  profilePhotoUrl?: string
  isExpired?: boolean | undefined
  isFunded?: boolean | undefined
  isForCard?: boolean
  limits?: RedemptionLimitPartsFragment[] | undefined | null
  validEnd?: string | undefined | null
  linkedProfileId?: string | null
  linkedProfilePhotoUrl?: string | null
  linkedProfileDisplayName?: string | null
  showSaveSection?: boolean
  saved?: boolean
  hasLimits?: boolean
  imageMaxHeight?: number
  imageMaxWidth?: number
  isArchived?: boolean
  handleSaveRedemption?: () => void
  handleUnsaveRedemption?: () => void
}) => {
  const navigate = useNavigate()

  const isCommunityProfilePhotoDefault =
    typeof profilePhotoUrl === 'string' &&
    profilePhotoUrl.includes('default/avatar')

  const profilePhotoOrRedemptionsPlaceholder = isCommunityProfilePhotoDefault
    ? redemptionsPlaceholder
    : profilePhotoUrl || ''

  const { hovered, ref } = useHover()

  const globalLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.Count &&
      limit.limitScope === RedemptionLimitScope.Global
  )

  const memberLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.Count &&
      limit.limitScope === RedemptionLimitScope.User
  )

  const intervalLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.RateLimit &&
      limit.limitScope === RedemptionLimitScope.User
  )

  const shouldShowLinkedProfilePhoto =
    linkedProfileId && linkedProfileId !== ownerProfileId

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (saved) {
      handleUnsaveRedemption?.()
    } else {
      handleSaveRedemption?.()
    }
  }

  const appliedLimits = [globalLimit, intervalLimit, memberLimit].filter(
    (limit) => limit !== undefined && limit !== null
  )

  return (
    <>
      {isForCard ? (
        <RedemptionCardImage
          hasLimits={hasLimits}
          limits={appliedLimits as RedemptionLimitPartsFragment[]}
          validEnd={validEnd || undefined}
          isArchived={isArchived}
          isFunded={isFunded}
          isExpired={isExpired}
          src={src}
          profilePhotoOrRedemptionsPlaceholder={
            profilePhotoOrRedemptionsPlaceholder
          }
          imageMaxHeight={imageMaxHeight}
          imageMaxWidth={imageMaxWidth}
        />
      ) : (
        <>
          <RedemptionDetailsImage
            isArchived={isArchived}
            isExpired={isExpired}
            isFunded={isFunded}
            src={src}
            srcSet={srcSet}
            validEnd={validEnd || undefined}
            limits={appliedLimits as RedemptionLimitPartsFragment[]}
            imageMaxHeight={imageMaxHeight}
            imageMaxWidth={imageMaxWidth}
          />

          {shouldShowLinkedProfilePhoto && (
            <Box
              ref={ref as React.RefObject<HTMLDivElement>}
              onClick={(event) => {
                navigate(`/profile/${linkedProfileDisplayName}`)
                event.preventDefault()
              }}
            >
              <Image
                src={linkedProfilePhotoUrl}
                alt="Linked profile"
                h={hovered ? 55 : 50}
                w={hovered ? 55 : 50}
                className="transition-width-height duration-100"
                pos="absolute"
                right={0}
                bottom={0}
                radius="xl"
                m={8}
                style={{ zIndex: 1 }}
              />
            </Box>
          )}
        </>
      )}

      {showSaveSection && (
        <>
          <Box
            pos="absolute"
            top={0}
            right={0}
            style={{
              borderLeft: '50px solid transparent',
              borderTop: '50px solid rgba(0,0,0,0.2)',
              borderTopRightRadius: '10px',
              zIndex: 1,
            }}
          />
          <Box
            pos="absolute"
            top={0}
            right={0}
            px={8}
            py={5}
            style={{ zIndex: 1 }}
            onClick={handleClick}
          >
            {saved ? (
              <FontAwesomeIcon
                icon={faHeartSolid}
                color="white"
                size="sm"
                className="hover:scale-110"
              />
            ) : (
              <FontAwesomeIcon
                icon={faHeartLight}
                color="white"
                size="sm"
                className="hover:scale-110"
              />
            )}
          </Box>
        </>
      )}
    </>
  )
}
