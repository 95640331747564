import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Sentry from '@sentry/react'

import { faMegaphone } from '@fortawesome/pro-solid-svg-icons'
import { Button, Text } from '@mantine/core'

export const SwayFeedbackButton = () => {
  const handleFeedbackClick = () => {
    const sentryClient = Sentry.getClient()

    if (
      sentryClient &&
      typeof sentryClient.getIntegrationByName === 'function'
    ) {
      const feedbackIntegration = sentryClient.getIntegrationByName(
        'Feedback'
      ) as ReturnType<typeof Sentry.feedbackIntegration>

      feedbackIntegration.createForm().then((form) => form.open())
    }
  }

  return (
    <Button variant="transparent" color="black" onClick={handleFeedbackClick}>
      <FontAwesomeIcon icon={faMegaphone} size="lg" />
      <Text size="sm" ml={12}>
        Contact support
      </Text>
    </Button>
  )
}
