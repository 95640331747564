import {
  Redemption,
  RedemptionLimit,
  RedemptionLimitInput,
  RedemptionLimitScope,
  RedemptionLimitType,
} from '@graphql'
import dayjs from 'dayjs'

/**
 * Check if a redemption is expired based on the validEnd date or the global limit
 * @param redemption
 * @returns boolean
 */
export function isRedemptionExpired(redemption: Redemption): boolean {
  if (!redemption) {
    return true
  }

  if (redemption.validEnd) {
    if (dayjs().isAfter(dayjs(redemption.validEnd))) {
      return true
    }
  }

  if (redemption.archivedAt) {
    return true
  }

  if (redemption.limits?.limits) {
    const { limits } = redemption.limits
    for (const limit of limits) {
      if (
        limit.limitScope == RedemptionLimitScope.Global &&
        redemption.redeemCount >= limit.limit
      ) {
        return true
      }
    }
  }

  return false
}

/**
 * Check if a redemption is funded based on the evaluation of the donation progress.
 * @param redemption
 * @returns boolean
 */
export function isRedemptionFunded(redemption: Redemption): boolean {
  if (!redemption.isNonProfit) {
    return false
  }

  if (!redemption.donationProgress) {
    return false
  }

  if (redemption.donationProgress?.progress == 100) {
    return true
  }

  return false
}

/**
 * Check if a redemption is available based on the validEnd date and the evaluation of the limits
 * @param redemption
 * @returns boolean
 */
export function isRedemptionAvailable(redemption: Redemption): boolean {
  if (!redemption) {
    return false
  }

  if (redemption.validEnd) {
    if (dayjs().isAfter(dayjs(redemption.validEnd))) {
      return false
    }
  }

  if (redemption.limits?.limits) {
    const { limits } = redemption.limits
    for (const limit of limits) {
      if (limit.blocking) {
        return false
      }
    }
  }

  return true
}

/**
 * Based on a list of redemption limits, looks for one that matches the given `scope` and `type`.
 * @param limits
 * @param limitType
 * @param limitScope
 * @returns RedemptionLimit | undefined
 */
export function findLimit(
  limits: Partial<RedemptionLimit | RedemptionLimitInput>[] | undefined,
  limitType: RedemptionLimitType,
  limitScope: RedemptionLimitScope
) {
  return limits?.find(
    (limit) => limit.limitType === limitType && limit.limitScope === limitScope
  )
}

/**
 * Based on a list of redemption limits, returns the index of the limit that matches the given `scope` and `type`.
 * @param limits
 * @param limitType
 * @param limitScope
 * @returns number
 */
export function findLimitIndex(
  limits: Partial<RedemptionLimit | RedemptionLimitInput>[],
  limitType: RedemptionLimitType,
  limitScope: RedemptionLimitScope
) {
  return limits.findIndex(
    (limit) => limit.limitType === limitType && limit.limitScope === limitScope
  )
}
