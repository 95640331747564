import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'
import { RequireMember } from '../../routes/guards/RequireMember'

export const MemberLeaderboardsRoutes = [
  {
    path: 'challenges/',
    async lazy() {
      const { LeaderboardsPage } = await import('./Leaderboards.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'MemberChallengesRoutes')
            }}
          >
            <RequireMember>
              <LeaderboardsPage />
            </RequireMember>
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: 'overview',
        async lazy() {
          const { ChallengesOverview } = await import(
            './screens/ChallengesOverview.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'MemberChallengesOverview')
                }}
              >
                <ChallengesOverview />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'leaderboards/sway_cash',
        async lazy() {
          const { SwayCashLeaderboard } = await import(
            '@components/leaderboards/swayCashLeaderboard/SwayCashLeaderboard'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'MemberLeaderboardsSwayCashLeaderboard'
                  )
                }}
              >
                <SwayCashLeaderboard />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'leaderboards/trivia',
        async lazy() {
          const { TriviaLeaderboard } = await import(
            '@components/leaderboards/triviaLeaderboard/TriviaLeaderboard'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'MemberLeaderboardsTriviaLeaderboard'
                  )
                }}
              >
                <TriviaLeaderboard />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: '*',
        async lazy() {
          const { RedirectPage } = await import('../Redirect.page')
          return {
            element: <RedirectPage route="/" />,
          }
        },
      },
    ],
  },
]
