import { Clipboard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { faCopy, faShareFromSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAnalytics from '@hooks/useAnalytics'
import { Button, Center, Divider, Modal, Stack } from '@mantine/core'
import { notifications } from '@util/notifications/notifications'
import { QRCodeCanvas } from 'qrcode.react'
import { useCallback } from 'react'
import { QRCodeEntitySharing, ShareSource } from '../../../constants'

export const ShareQrCodeModal = ({
  url,
  type,
  title,
  description,
  qrCodeEntitySharing,
  shareSource = ShareSource.ENTITY_PAGE,
  open,
  setOpen,
  children,
}: {
  url: string
  type: string
  title?: string
  description?: string
  // The entity that is being shared (Redemption, Profile, etc.)
  qrCodeEntitySharing: QRCodeEntitySharing
  shareSource?: ShareSource
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
}) => {
  const { track } = useAnalytics()

  // Share and copy only work via https on web.
  // If you need to test, use ngrok, and modify .env.local to point to your ngrok urls.
  const shareItem = async (
    url: string,
    title?: string,
    description?: string
  ) => {
    const canShare = (await Share.canShare()).value
    if (canShare) {
      await Share.share({
        title: title,
        text: description,
        url: url,
      })
        .then((shareResult) => {
          track(`${qrCodeEntitySharing}-share-item`, {
            additional_properties: {
              activityType: shareResult.activityType,
              shareSource,
            },
          })
        })
        .catch((e) => {
          if (e.name === 'AbortError') {
            track(`${qrCodeEntitySharing}-share-item-aborted`, {
              additional_properties: {
                shareSource,
              },
            })
            return
          }
        })
    } else {
      handleCopyUrl(url)
    }
  }

  const handleCopyUrl = useCallback((url: string) => {
    Clipboard.write({
      string: url,
    })
      .then(() => {
        notifications.show({
          title: `${type} link copied`,
          message: `${type} link has been copied to your clipboard`,
        })
        track(`${qrCodeEntitySharing}-share-link`, {
          additional_properties: {
            activityType: 'copied',
            shareSource,
          },
        })
      })
      .catch((e) => {
        console.error(e)
        notifications.show({
          title: 'Error',
          message: `There was an error copying ${type} link to your clipboard`,
          color: 'red',
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      title={title}
      opened={open}
      onClose={() => setOpen(false)}
      className="bg-white"
    >
      <Center>
        <Stack>
          <Stack align="center">
            <QRCodeCanvas
              value={url}
              size={300}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              marginSize={0}
            />
          </Stack>
          <Divider size="md" my="xs" label="OR" labelPosition="center" />
          <Stack>
            <Button
              className="flex w-full items-center justify-center"
              variant="outline"
              color="swurple"
              onClick={() => shareItem(url, title, description)}
            >
              <FontAwesomeIcon icon={faShareFromSquare} className="mr-1" />
              Share
            </Button>
            {!Capacitor.isNativePlatform() && (
              <Button
                className="flex w-full items-center justify-center"
                variant="outline"
                color="swurple"
                onClick={() => handleCopyUrl(url)}
              >
                <FontAwesomeIcon icon={faCopy} className="mr-1" />
                Copy Link
              </Button>
            )}
          </Stack>
          {children}
        </Stack>
      </Center>
    </Modal>
  )
}
