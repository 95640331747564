import { Capacitor } from '@capacitor/core'
import { PermissionStatus } from '@capacitor/geolocation'
import useGeolocationStore from '@stores/useGeolocationStore'
import { isEqual } from 'lodash-es'
import { useCallback } from 'react'
import useAnalytics from './useAnalytics'

export const useGeolocation = () => {
  const analytics = useAnalytics()

  const emitLocationPermission = useCallback(
    (locationPermission: PermissionStatus) => {
      analytics.locationPermission({
        locationPermission: locationPermission.location,
        platform: Capacitor.getPlatform() as 'ios' | 'android' | 'web',
      })
    },
    [analytics]
  )

  const position = useGeolocationStore((state) => state.position)
  const lastPositionTimestamp = useGeolocationStore(
    (state) => state.lastPositionTimestamp
  )
  const setPermissions = useGeolocationStore((state) => state.setPermissions)
  const setPermissionsChecked = useGeolocationStore(
    (state) => state.setPermissionsChecked
  )
  const permissions = useGeolocationStore((state) => state.permissions)
  const permissionsChecked = useGeolocationStore(
    (state) => state.permissionsChecked
  )
  const error = useGeolocationStore((state) => state.error)
  const setError = useGeolocationStore((state) => state.setError)
  const updatePosition = useGeolocationStore((state) => state.updatePosition)
  const updateLastPositionTimestamp = useGeolocationStore(
    (state) => state.updateLastPositionTimestamp
  )

  const handleSetPermissions = (newPermissions: PermissionStatus) => {
    setPermissions(newPermissions)
    // * Only if permissions are different from the previous permissions
    if (!isEqual(permissions, newPermissions)) {
      emitLocationPermission(newPermissions)
    }
  }

  return {
    position,
    lastPositionTimestamp,
    permissions,
    permissionsChecked,
    error,
    setError,
    setPermissionsChecked,
    updatePosition,
    updateLastPositionTimestamp,
    setPermissions: handleSetPermissions,
  }
}
