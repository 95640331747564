const ROOT_URL = import.meta.env.VITE_ROOT_URL
const ROOT_LINK_SERVER_URL = import.meta.env.VITE_ROOT_LINK_SERVER_URL
const ROOT_DOMAIN = new URL(ROOT_URL).hostname
const ROOT_LINK_DOMAIN = new URL(ROOT_LINK_SERVER_URL).hostname
const ROOT_API_DOMAIN = new URL(import.meta.env.VITE_ROOT_API_SERVER_URL)
  .hostname
const ALLOWED_DOMAINS = [ROOT_DOMAIN, ROOT_LINK_DOMAIN, ROOT_API_DOMAIN]

export function removeDomain(url: URL | string) {
  const parsedUrl = new URL(url)
  return parsedUrl.pathname.slice(1)
}

export function isAllowedDomain(
  url: URL | string,
  allowedDomains = ALLOWED_DOMAINS
) {
  try {
    const parsedUrl = new URL(url)
    const domain = parsedUrl.hostname
    return allowedDomains.includes(domain)
  } catch {
    console.error('Invalid URL:', url)
    return false
  }
}

export function isRootApiDomain(
  url: URL | string,
  rootApiDomain = ROOT_API_DOMAIN
) {
  try {
    const parsedUrl = new URL(url)
    const domain = parsedUrl.hostname
    return domain === rootApiDomain
  } catch {
    console.error('Invalid URL:', url)
    return false
  }
}

export function isRootLinkDomain(
  url: URL | string,
  rootLinkDomain = ROOT_LINK_DOMAIN
) {
  try {
    const parsedUrl = new URL(url)
    const domain = parsedUrl.hostname
    return domain === rootLinkDomain
  } catch {
    console.error('Invalid URL:', url)
    return false
  }
}
